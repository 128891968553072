
import config from 'config'

export const currencyFormat = (numero, currency) => {
    /*return new Intl.NumberFormat(config.locale, { 
        style: 'currency', 
        currency: currency || config.currency 
    }).format(numero)*/

    return `Gs. ${roundNumber(numero, 0, true).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}`;
}

export const roundNumber = (numero, decimales = 2, string = false) => {
    numero = parseFloat(numero) || 0
    numero = (Math.round(numero*Math.pow(10,decimales))/Math.pow(10,decimales)).toFixed(decimales)
    return string ? numero : parseFloat(numero)
}