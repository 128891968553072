import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from "react-router-dom"
import qs from 'qs'

import { IonAlert } from '@ionic/react'

import { AuthService } from 'services'

import { App, Content, Input, Button } from 'components'

export const ResetPassword = () => {

    const history = useHistory()
    const params = useParams()

    const [password, setPassword] = useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('')

    const [email, setEmail] = useState('')
    const [errors, setErrors] = useState({})

    const [success, setSuccess] = useState(false);
    const [showAlertError, setShowAlertError] = useState(false);
    const [submitting, setSubmitting] = useState(false)

    const reset = async () => {
        setErrors([])
        setSubmitting(true)
        try {
            await AuthService.reset({
                token: params.token,
                email,
                password,
                password_confirmation: passwordConfirmation
            })

            setSuccess(true)
        } catch (error) {
            if (error?.response?.data?.errors) {
                setErrors(error?.response?.data?.errors)
            } else {
                setShowAlertError(true)
            }
        }
        setSubmitting(false)
    }

    useEffect( () => {
        let email = qs.parse(history.location.search, { ignoreQueryPrefix: true }).email
        if(email) {
            setEmail(email)
        }
    }, [])

    return (
        <App title="Restablecer contraseña" titlebar >
            <Content margin>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <article className="wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.5s">
                            Para restablecer su contraseña, introduzca su dirección de correo electrónico y la contraseña nueva.
                        </article>
                        <form className="FormularioLogin FormularioBase mt-2" onSubmit={(e) => e.preventDefault()}>
                            <Input
                                type="email"
                                value={email}
                                placeholder="Correo electrónico"
                                onChange={e => setEmail(e.target.value)}
                                disabled={submitting}
                                required
                                errors={errors['email']}
                            />

                            <Input type="password"
                                placeholder="Contraseña nueva"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                errors={errors['password']}
                                disabled={submitting}
                            />

                            <Input type="password"
                                placeholder="Confirme la contraseña nueva"
                                value={passwordConfirmation}
                                onChange={e => setPasswordConfirmation(e.target.value)}
                                errors={errors['password_confirmation']}
                                disabled={submitting}
                            />
                            
                            <div className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="1.5s">
                                <div className="form-group">
                                    <Button  type="button" color="primary" onClick={() => reset()} >Restablecer contraseña</Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Content>

            <IonAlert
                isOpen={success}
                onDidDismiss={() => history.replace('/login')}
                header={'¡Contraseña restablecida!'}
                message={'Su contraseña ha sido restablecida con éxito. Por favor, utiliza esta nueva contraseña para iniciar sesión'}
                buttons={['Aceptar']}
            />
            
            <IonAlert
                isOpen={showAlertError}
                onDidDismiss={() => setShowAlertError(false)}
                header={'No se puedo restablecer la cuenta'}
                message={'Intente nuevamente mas tarde'}
                buttons={['Aceptar']}
            />
        </App>

    )
}