import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from "react-router-dom"

import {
    IonAlert,
    IonLoading,
    IonRefresher,
    IonRefresherContent,
    useIonViewWillEnter
} from '@ionic/react'

import {
    UserService,
    BankAccountService,
    StoreService,
    FacebookService
} from 'services'

import { useContext } from 'hooks'

import { App, Content, Input, Spinner, Button, Image, ButtonBox } from 'components'

import imagenPerfil from 'assets/img/perfil.jpg'

export const Profile = () => {

    const history = useHistory()
    const { auth } = useContext()

    const refresh = useRef()

    const [user, setUser] = useState()
    const [statistics, setStatistics] = useState({})
    const [bankAccounts, setBankAccounts] = useState([])
    const [stores, setStores] = useState([])
    const [profileCompleted, setProfileCompleted] = useState(true)
    const [completed, setCompleted] = useState(false)
    const [showConfirmLogout, setShowConfirmLogout] = useState(false);
    const [errors, setErrors] = useState({})
    const [showAlertError, setShowAlertError] = useState(false);
    const [submitting, setSubmitting] = useState(false)
    const [loading, setLoading] = useState(true)

    const load = async (isRefresh = false) => {
        let [
            user,
            statistics,
            bankAccounts,
            stores
        ] = await Promise.all([
            UserService.user(),
            UserService.statistics(),
            BankAccountService.list(),
            StoreService.list()
        ]);

        isRefresh && refresh.current && refresh.current.complete();

        setUser(user)
        setStatistics(statistics)
        setBankAccounts(bankAccounts)
        setStores(stores)
        setProfileCompleted(user.profile_completed)
        setLoading(false)
    }

    const linkFacebook = async () => {
        setSubmitting(true)
        try {
            if (await FacebookService.link()) {
                setUser({ ...user, facebook_link: true })
                history.push(`/profile/facebook/link`, { user: { ...user, facebook_link: true } })
            }
        } catch (error) {
            console.log(error);
        }
        setSubmitting(false)
    }

    const configuracionFacebook = async () => {
        try {
            if(user.facebook_catalog_link) {
                history.push(`/profile/facebook`, { user })
            } else {
                history.push(`/profile/facebook/link`, { user })
            }
        } catch (error) {
            console.log(error);
        }
    }
    const goHome = () => {
        setProfileCompleted(true)
        history.replace(`/`)
    }

    const logout = async () => {
        setSubmitting(true)

        try {
            await auth.logout()
            history.replace('/login')
            return
        } catch (error) { }

        setSubmitting(false)
    }


    useEffect(() => {
        load();
    }, [])

    useIonViewWillEnter(() => {

        if (history.location?.state?.user) {
            setUser(history.location.state.user)
        }

        if (history.location?.state?.bankAccount) {
            setBankAccounts([history.location.state.bankAccount])
        }

        if (history.location?.state?.store) {
            setStores([history.location.state.store])
        }

        if (history.location?.state?.force) {
            setProfileCompleted(false)
        }
    })

    useEffect(() => {
        if (!profileCompleted && stores.length > 0 && bankAccounts.length > 0) {
            setCompleted(true)
        }
    }, [stores, bankAccounts, profileCompleted])

    return (
        <App title="Mi cuenta" titlebar backButton={false}>
            <Content margin>
                <IonRefresher slot="fixed" onIonRefresh={() => load(true)} ref={refresh}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <article className="wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.5s">
                            {!user ? <Spinner /> : <>
                                <div className="App-MiCuenta-Header">
                                    <Image path={user.avatar} invalid={imagenPerfil} />
                                    <h1 className="Nombre FontTitle1">{user.first_name} {user.last_name}</h1>
                                    {/*<a className="BtnEdit BorderAll" href="#">Editar perfil</a>*/}
                                </div>
                                <div className="App-MiCuenta-Estadisticas">
                                    <div className="BoxEstadistica BoxReputacion BorderAll BgMorado">
                                        <p className="Title">Publicaciones</p>
                                        <span className="Valor">{statistics.total_posts}</span>
                                    </div>
                                    <div className="BoxEstadistica BoxPublicaciones BorderAll BgMorado">
                                        <p className="Title">Ventas</p>
                                        <span className="Valor">{statistics.total_sales}</span>
                                    </div>
                                </div>
                                {/*
                                <div className="App-MiCuenta-Estadisticas">
                                <div className="BoxEstadistica BoxPendientes BorderAll BgRosa1">
                                <p className="Title">Mensajes pendientes</p>
                                <span className="Valor">{statistics.total_messages || 0}</span>
                                </div>
                                <div className="BoxEstadistica BoxVentas BorderAll BgRosa1">
                                <p className="Title">Ventas</p>
                                <span className="Valor">{statistics.total_sales || 0}</span>
                                </div>
                                </div>
                            */}
                                <div className="App-MiCuenta-DatosFormularios mt-3">
                                    <div className="App-MiCuenta-DatosFormularios-Box mb-4 wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.5s">
                                        {
                                            user.facebook_link
                                                ? <Button onClick={configuracionFacebook} color="facebook">Editar vinculo con Facebook</Button>
                                                : <Button onClick={linkFacebook} color="facebook">Vincular Facebook</Button>
                                        }
                                    </div>
                                    <div className="App-MiCuenta-DatosFormularios-Box mb-4 wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.5s">
                                        <h2 className="Title FontTitle1 mb-3">Datos personales</h2>
                                        <form className="FormularioLogin FormularioBase" action="#">
                                            <Input type="text" disabled placeholder="Nombre" value={user.first_name} />
                                            <Input type="text" disabled placeholder="Apellido" value={user.last_name} />
                                            <Input type="email" disabled placeholder="Correo electrónico" value={user.email} />
                                            <Input type="text" disabled placeholder="Teléfono" value={user.phone} />
                                            <Button onClick={e => history.push(`/profile/edit`, { user })} color="primary">
                                                Editar datos personales
                                            </Button>
                                        </form>
                                    </div>
                                    <div className="App-MiCuenta-DatosFormularios-Box mb-4 wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.5s">
                                        <h2 className="Title FontTitle1 mb-3">Datos bancarios</h2>
                                        <p className="FontTitle2">Actualice sus datos para poder recibir las transacciones de sus clientes</p>
                                        {
                                            bankAccounts[0] ?
                                                <form className="FormularioLogin FormularioBase" action="#">
                                                    <Input type="text" disabled placeholder="Titular" value={bankAccounts[0].name} />
                                                    <Input type="text" disabled placeholder="Banco" value={bankAccounts[0].bank.name} />
                                                    <Input type="text" disabled placeholder="Moneda" value={`${bankAccounts[0].currency.code} - ${bankAccounts[0].currency.name}`} />
                                                    {/*<Input type="email" disabled placeholder="CBU" value={bankAccounts[0].cbu} />*/}
                                                    {/*bankAccounts[0].alias && <Input type="text" disabled placeholder="Alias" value={bankAccounts[0].alias} />*/}
                                                    <Input type="text" disabled placeholder="Numero de cuenta" value={bankAccounts[0].account} />
                                                    <Input type="text" disabled placeholder="Documento" value={`${bankAccounts[0].document_type.name}: ${bankAccounts[0].document}`} />

                                                    <Button onClick={e => history.push(`/bank-accounts/${bankAccounts[0].id}/edit`, { bankAccount: bankAccounts[0] })} color="primary">
                                                        Editar datos bancarios
                                                </Button>
                                                </form> :
                                                <ButtonBox onClick={e => history.push(`/bank-accounts/create`, { create: true })} icon="plus">
                                                    Agregar datos bancarios
                                            </ButtonBox>
                                        }
                                    </div>
                                    <div className="App-MiCuenta-DatosFormularios-Box mb-4 wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.5s">
                                        <h2 className="Title FontTitle1 mb-3">Ubicación del local</h2>
                                        <p className="FontTitle2">Actualice su ubicación para que nuestro delivery pueda retirar los productos que sus clientes adquieran</p>
                                        {
                                            stores[0] ?
                                                <form className="FormularioLogin FormularioBase" action="#">
                                                    <Input type="text" disabled placeholder="Nombre" value={stores[0].name} />
                                                    <Input type="text" disabled placeholder="Pais" value={stores[0].country.name} />
                                                    <Input type="text" disabled placeholder="Provincia" value={stores[0].state.name} />
                                                    <Input type="text" disabled placeholder="Ciudad" value={stores[0].city.name} />
                                                    {stores[0].district && <Input type="text" disabled placeholder="Barrio" value={stores[0].district} />}
                                                    <Input type="text" disabled placeholder="Calle 1" value={stores[0].street_name} />
                                                    {stores[0].street_name_alt && <Input type="text" disabled placeholder="Calle 2" value={stores[0].street_name_alt} />}
                                                    {stores[0].between_streets && <Input type="text" disabled placeholder="Entre calles" value={stores[0].between_streets} />}
                                                    {stores[0].building_name && <Input type="text" disabled placeholder="Nombre Edificio" value={stores[0].building_name} />}
                                                    <Input type="text" disabled placeholder="Numero de calle" value={stores[0].street_number} />
                                                    {stores[0].zip_code && <Input type="text" disabled placeholder="Codigo postal" value={stores[0].zip_code} />}
                                                    {stores[0].reference && <Input type="text" disabled placeholder="Referencia" value={stores[0].reference} />}
                                                    <Button onClick={e => history.push(`/stores/location/${stores[0].id}/edit`, { store: stores[0] })} color="primary">
                                                        Editar ubicación del local
                                                </Button>
                                                </form> :
                                                <ButtonBox onClick={e => history.push(`/stores/location/create`, { create: true })} icon="plus">
                                                    Agregar ubicación del local
                                            </ButtonBox>
                                        }
                                    </div>
                                    <div className="App-MiCuenta-DatosFormularios-Box mb-4 wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.5s">
                                        <Button mt={3} onClick={() => history.push(`/products`)} color="primary">Agregar productos</Button>
                                    </div>
                                    <div className="App-MiCuenta-DatosFormularios-Box mb-4 wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.5s">
                                        <Button mt={3} onClick={() => setShowConfirmLogout(true)} color="danger">Cerrar Sesión</Button>
                                    </div>
                                </div>
                            </>
                            }
                        </article>
                    </div>
                </div>
            </Content>
            <IonAlert
                isOpen={showConfirmLogout}
                onDidDismiss={() => setShowConfirmLogout(false)}
                header={'Cerrar sesion'}
                message={'¿Esta seguro que quiere cerrar sesion?'}
                buttons={[
                    {
                        text: 'Cancelar',
                        role: 'cancel',
                        cssClass: 'secondary'
                    },
                    {
                        text: 'Aceptar',
                        handler: () => logout()
                    }
                ]}
            />
            <IonAlert
                isOpen={showAlertError}
                onDidDismiss={() => setShowAlertError(false)}
                header={'No se puedo crear la cuenta'}
                message={'Intente nuevamente mas tarde'}
                buttons={['Aceptar']}
            />

            <IonLoading
                isOpen={submitting}
                backdropDismiss={false}
                keyboardClose={false}
                message={'Espere por favor...'}
                duration={0}
            />
        </App>
    )
}