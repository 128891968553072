import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from "react-router-dom"

import { Plugins } from '@capacitor/core';

import { ProductService } from 'services'

import { App, Content, Carousel, CarouselImage, Button, Input, Select } from 'components'

import { currencyFormat } from 'common'
import { IonAlert } from '@ionic/react';

import config from 'config'

const { Share, Browser } = Plugins;

export const BuyProduct = () => {
    const $ = window.jQuery

    const history = useHistory()
    const params = useParams()

    const [showAlertError, setShowAlertError] = useState(false);

    const [product, setProduct] = useState(undefined)
    const [quantity, setQuantity] = useState()
    const [valid, setValid] = useState(false)

    const [showDetail, setShowDetail] = useState(false)
    const [showLimitDetail, setShowLimitDetail] = useState(false)

    const loadProduct = async () => {
        try {
            let product = await ProductService.detail(params.id)
            setProduct(product)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        setProduct(history.location?.state?.product)
        loadProduct()
    }, [])


    useEffect(() => {
        setValid(quantity > 0)
    }, [quantity])

    const toggleDetail = (event) => {
        event.preventDefault()
        setShowDetail(!showDetail);
    }

    const handleShowLimitDetail = (ref) => {
        if (ref && ref.clientHeight >= 150) {
            setShowLimitDetail(true)
        }
    }

    const handleBuy = (event) => {
        event.preventDefault()
        if (parseInt(quantity) > product.stock) {
            setShowAlertError(true);
            return;
        }

        history.push(`/${params.id}-${params.slug}/payment`, { product, quantity })
    }

    return (
        <App title={product?.name || 'Detalle del artículo'} titlebar backButton={false} transparent>
            <Content className="App-Articulo">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 p-0">
                        <article className="App-Articulo wow fadeInUp">
                            {
                                product && <>
                                    <Carousel>
                                        {product.gallery.map((photo, index) => <CarouselImage key={index} path={photo} />)}
                                    </Carousel>
                                    <div className="App-Articulo-Detalle px-3">
                                        <div className="App-Brands mb-2">
                                            {
                                                (product.user?.shopping && product.user?.shopping.logo)
                                                    ? <img className="ImgLogo" src={`${config.url}/storage/${product.user.shopping.logo}`} alt={product.user.shopping.name} />
                                                    : <img className="ImgLogo" src="assets/img/storeados-isologo-rose.png" alt="Storeados" />
                                            }
                                        </div>
                                        <div className="App-Articulo-Detalle-Header">
                                            <div className="BoxTitle">
                                                <h2 className="Title FontTitle1">{product?.name || 'Detalle del artículo'}</h2>
                                                <p className="Categorias"><span className="Categoria">{product.category?.category?.name}</span><span className="Categoria">{product.category.name}</span></p>
                                            </div>
                                            <span className="Numero BgVioleta BorderAll">{currencyFormat(product.price)}</span>
                                        </div>
                                        <div className="App-Articulo-Detalle-Descripcion">
                                            <h2 className="Title FontTitle1">Descripción</h2>
                                            <div className={`BoxText ${showDetail ? 'ShowText' : ''}`} ref={(ref) => handleShowLimitDetail(ref)}>
                                                <p className="Text">{product.detail}</p>
                                            </div>
                                            {
                                                showLimitDetail && <div className="w-100 text-center">
                                                    <a className="LinkVerMas BorderAll" onClick={toggleDetail} href="#">{!showDetail ? 'Ver más' : 'Ocultar'}
                                                        <i className={`fa fa-angle-${!showDetail ? 'down' : 'up'}`}></i>
                                                    </a>
                                                </div>
                                            }
                                        </div>
                                        {

                                            /*<div className="App-Articulo-Detalle-Entrega">
                                                <h2 className="Title FontTitle1">Entrega</h2>
                                                <p className="Text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Velit, non.</p>
                                            </div>
                                            <div className="App-Articulo-Detalle-Pagos">
                                                <h2 className="Title FontTitle1">Pagos habilitados</h2>
                                                <div className="BoxPagos">
                                                    <div className="BoxPago"><img src="assets/img/articulo/logo-1.jpg" alt="" /></div>
                                                    <div className="BoxPago"><img src="assets/img/articulo/logo-1.jpg" alt="" /></div>
                                                    <div className="BoxPago"><img src="assets/img/articulo/logo-1.jpg" alt="" /></div>
                                                    <div className="BoxPago"><img src="assets/img/articulo/logo-1.jpg" alt="" /></div>
                                                    <div className="BoxPago"><img src="assets/img/articulo/logo-1.jpg" alt="" /></div>
                                                </div>
                                            </div>
                                            <div className="App-Articulo-Detalle-Boton">
                                                <button type="submit" className="BtnSubmit BgDegradeRosaH BtnAction BorderAll">Comprar</button>
                                            </div>*/
                                        }
                                        <form className="FormularioLogin FormularioBase" action="#">
                                            <Select
                                                empty
                                                placeholder="Cantidad *"
                                                value={quantity}
                                                onChange={e => setQuantity(e.target.value)}
                                            >
                                                {Array(product.stock).fill(0).map((_, index) => <option key={index} value={index + 1}>{index + 1}</option>)}
                                            </Select>
                                            <Button color="primary" mt="3" mb="5"
                                                disabled={!valid}
                                                onClick={handleBuy}>
                                                Comprar
                                            </Button>
                                        </form>
                                    </div>
                                </>
                            }
                        </article>
                    </div>
                </div>
            </Content>
            <IonAlert
                isOpen={showAlertError}
                onDidDismiss={() => setShowAlertError(false)}
                header={'Stock Insuficiente'}
                buttons={['Aceptar']}
            />
        </App>
    )
}