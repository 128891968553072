import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from "react-router-dom"

import { IonLoading } from '@ionic/react'

import { App, Content, Maps } from 'components'

import { Plugins } from '@capacitor/core';

export const StoreLocation = () => {

    const history = useHistory()
    const params = useParams()
    const map = useRef()

    const [submitting, setSubmitting] = useState(false)
    const [loading, setLoading] = useState(false)

    const complete = (location) => {
        console.log(location)
        if(params.action == 'create') {
            history.push('/stores/create', {...history.location.state,location})
        } else {
            history.push(`/stores/${params.id}/edit`, {...history.location.state,location})
        }
    }
      
    return (
        <App title="Seleccione la ubicacion" titlebar icon="crosshairs" onButton={() => map.current.centerGPSLocation() } onBack={() => history.goBack()}>
            <Content messages={false}>
                <Maps 
                autolocation={!history.location.state?.store}
                marker={ history.location.state?.store && {
                    lat: history.location.state?.store.latitude,
                    lng: history.location.state?.store.longitude
                }} 
                location={ history.location.state?.store && {
                    lat: history.location.state?.store.latitude,
                    lng: history.location.state?.store.longitude
                }} 
                onClick={(location) => console.log(location)}
                onContinue={complete} ref={map}
            />
            </Content>
       
            <IonLoading
                isOpen={loading || submitting}
                backdropDismiss={false}
                keyboardClose={false}
                message={'Espere por favor...'}
            />
        </App>
    )
}

