import React, { useEffect, useState } from 'react';

export const SelectOptions = ({ title = "Entrega", value = [], options = [], icons = {}, errors, onChange, multiple = false}) => {

    const [currentValues, setValue] = useState([]);
    
    useEffect(() => {
        setValue(value);
    }, [value])
    
    const isSelected = (id) => {
        return currentValues.findIndex(v => v === id) > -1
    }

    const toogleSelection = (id) => {
        let selections = [...currentValues];

        if(multiple) {
            let index = selections.findIndex(v => v === id)
            if (index > -1) {
                selections.splice(index, 1)
            } else {
                selections = selections.concat(id)
            }           
        } else {
            selections = [id]
        }

        onChange && onChange(selections)
    }

    return (
        <>
            <div className="App-DatosPago-Header">
                <h2 className="Title FontTitle1 mb-3">{title}</h2>
            </div>
            <div className={`App-DatosPago-Forma ${ errors ? 'is-invalid' : ''}`}>
                {
                    options.map((delivery, index) =>
                        <div key={delivery.id}
                            className={`BoxFormaPago BoxTipoEnvio BorderAll BgAzul ${isSelected(delivery.id) && !delivery.disabled ? 'Active' : ''} ${delivery.disabled ? 'BoxTipoEnvioDisabled' : ''}`}
                            onClick={() => !delivery.disabled && toogleSelection(delivery.id)}
                        >
                            <i className="IconChek fa fa-check-circle-o"></i>
                            <i className={`Icon IconTarjeta fa fa-${delivery.icon || icons[delivery.id]}`}></i>
                            <p className="Title">{delivery.name}</p>
                        </div>
                    )
                }
            </div>
            {
                errors && errors.map((message, index) => (
                    <div className="invalid-feedback" key={index}>
                        {message}
                    </div>
                ))
            }
        </>

    )
}
