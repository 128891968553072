import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from "react-router-dom"
import {
    IonLoading,
    IonAlert
} from '@ionic/react'

import { PaymentOrderService } from 'services'

import { App, Content, Button, Input, Spinner } from 'components'
import { PaymentBancard } from './PaymentBancard'

export const Checkout = () => {

    const history = useHistory()
    const params = useParams()

    const [loading, setLoading] = useState(true)
    const [showAlertError, setShowAlertError] = useState(false)
    const [alertMessage, setAlertMessage] = useState()
    const [submitting, setSubmitting] = useState(false)

    const [purchaseOrder, setPurchaseOrder] = useState()


    const load = async () => {
        setLoading(true)
        try {
            let purchaseOrder = await PaymentOrderService.detailByUUID(params.uuid)
            setPurchaseOrder(purchaseOrder)
        } catch (error) {
            console.error(error)
        }
        setLoading(false)
    }

    useEffect(() => {
        load()
    }, [])

    if (!purchaseOrder) {
        return (
            <App title="Realizar Pago"  titlebar backButton={false}>
                <Content margin>
                    <Spinner />
                </Content>
            </App>
        )
    }

    const product = purchaseOrder.products[0].product
    const paymentOrder = purchaseOrder.payment_orders[0]

    return (
        <App title="Realizar Pago" titlebar backButton={false}>
            <Content margin>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <article className="App-DatosPago wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.5s">
                            <div className="App-Articulo-Detalle  px-0">
                                <div className="App-Brands mb-2">
                                    <img className="ImgLogo" src="assets/img/storeados-isologo-rose.png" alt="Storeados" />
                                </div>
                                {
                                    product && <>
                                        <div className="App-Articulo-Detalle-Header">
                                            <div className="BoxTitle">
                                                <h2 className="Title FontTitle1">{product?.name || 'Detalle del artículo'}</h2>
                                                <p className="Categorias"><span className="Categoria">{product.category?.category?.name}</span><span className="Categoria">{product.category?.name}</span></p>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                            {paymentOrder && <PaymentBancard hash={paymentOrder.payment_hash} />}
                        </article>
                    </div>
                </div>
            </Content>
            <IonAlert
                isOpen={showAlertError}
                onDidDismiss={() => setShowAlertError(false)}
                header={'No se puedo realizar la compra'}
                message={alertMessage || 'Intente nuevamente mas tarde'}
                buttons={['Aceptar']}
            />

            <IonLoading
                isOpen={loading}
                backdropDismiss={false}
                keyboardClose={false}
                message={'Espere por favor...'}
            />
        </App >
    )
}