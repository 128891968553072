import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from "react-router-dom"

import { IonLoading, IonAlert, useIonViewWillEnter } from '@ionic/react'

import { FacebookService } from 'services'

import { App, Content, Input, Spinner, Button, Image, Select } from 'components'

export const EditFacebookLink = () => {

    const history = useHistory()
    const params = useParams()

    const [errors, setErrors] = useState({})

    const [showAlertError, setShowAlertError] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [loading, setLoading] = useState(true)

    const [user, setUser] = useState()
    const [catalogs, setCatalogs] = useState([])
    const [catalog, setCatalog] = useState()
    const [valid, setValid] = useState(false)

    const unlinkFacebook = async () => {
        setSubmitting(true)
        try {
            if (await FacebookService.unlink()) {
                history.replace('/profile')
            }
        } catch (error) {
            console.log(error);
        }
        setSubmitting(false)
    }

    const linkCatalog = async () => {
        setSubmitting(true)
        try {
            await FacebookService.linkCatalog(catalog)
            setUser({ ...user, facebook_catalog_link: true })
            history.replace('/profile/facebook', { user: { ...user, facebook_catalog_link: true } })
        } catch (error) {
            console.log(error);
        }
        setSubmitting(false)
    }


    const load = async () => {
        setLoading(true)
        try {
            let catalogs = await FacebookService.catalogs();
            setCatalogs(catalogs)
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }

    useEffect(() => {
        const user = history.location?.state?.user;
        if (!user) {
            history.replace('/profile')
        }

        setUser(user)

        load()
    }, [])

    useEffect(() => {
        setValid(catalog && catalog != "")
    }, [catalog])


    return (
        <App title="Facebook" titlebar onBack={() => history.goBack()}>
            <Content margin>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <article className="wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.5s">
                            <div className="App-MiCuenta-DatosFormularios mt-4">
                                <div className="App-MiCuenta-DatosFormularios-Box mb-4 wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.5s">
                                    <h2 className="Title FontTitle1 mb-3">Vincular Catalogo</h2>
                                    <p className="FontTitle2">Seleccione el catalogo con el cual se sincronizaran los productos publicados</p>
                                    <div className="FormularioLogin FormularioBase" action="#">
                                        <Select
                                            empty
                                            value={catalog}
                                            onChange={e => setCatalog(e.target.value)}
                                            placeholder="Catalogo *">
                                            {
                                                catalogs.map((catalog) =>
                                                    <optgroup key={catalog.id} label={catalog.name}>
                                                        {
                                                            catalog.catalogs.length > 0
                                                                ? catalog.catalogs.map((catalog) => <option key={catalog.id} value={catalog.id}>{catalog.name}</option>)
                                                                : <option disabled>Sin Categoria</option>
                                                        }
                                                    </optgroup>
                                                )
                                            }
                                        </Select>
                                        <Button onClick={linkCatalog} mb={5} color="primary" disabled={!valid || submitting}>Vincular Catalogo</Button>

                                        <Button onClick={unlinkFacebook} color="danger">Desvincular Facebook</Button>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </Content>
            <IonAlert
                isOpen={showAlertError}
                onDidDismiss={() => setShowAlertError(false)}
                header={'No se puedo actualizar el perfil'}
                message={'Intente nuevamente mas tarde'}
                buttons={['Aceptar']}
            />

            <IonLoading
                isOpen={loading || submitting}
                backdropDismiss={false}
                keyboardClose={false}
                message={'Espere por favor...'}
            />

        </App>
    )
}

