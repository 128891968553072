
import React, { useEffect, useRef } from 'react';

export const Carousel = ({ children }) => {
    let ref = useRef()

    useEffect(() => {
        const $ = window.jQuery
        $(ref.current).owlCarousel({
            loop: true,
            margin: 0,
            center: false,
            autoWidth: false,
            items: 1,
            autoplay: true,
            nav: false,
            dots: true,
            smartSpeed: 600,
            responsiveClassName: true
        });

        $('[data-fancybox="gallery"]').fancybox({
            infobar : false,
            loop : true,
            buttons: [
                'close'
            ],
            thumbs: {
                autoStart: false
            }
        });
    }, [])

    return (
        <div className="App-Articulo-Slider mb-3" id="App-Articulo-Slider">
            <div className="Articulo-Slider owl-carousel owl-theme owl-loaded " ref={ref}>
                {children}
            </div>
        </div>
    )
}
