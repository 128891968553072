import 'facebook'

import { client } from 'client'

import config from 'config'

import { Facebook } from '@ionic-native/facebook'

import { isPlatform } from '@ionic/react'

const FB_DEFAULT_PERMISSIONS = [
    'public_profile', 
    'email'
]

const FB_LINK_PERMISSIONS = [
    'public_profile', 
    'email', 
    'catalog_management',
    'pages_show_list'
]

if(!isPlatform('hybrid')) {     
    if(!window.FB) {
        throw Error('Facebook SDK invalid')
    }

    window.FB.init({
        appId: config.facebook_client_id,
        status: true,
        xfbml: true,
        version: 'v7.0'
    })

    window.FB.AppEvents.logPageView()
}

export const loginStatus = () => {
    return new Promise( (resolve, reject) => {
        window.FB.getLoginStatus((response) => {
            if (response.status === 'connected') {
                resolve(response.authResponse)
            }

            reject(response)
        })
    })   
}

export const login = (permissions = FB_DEFAULT_PERMISSIONS) => {
    if(isPlatform('hybrid')) {
        return Facebook.login(permissions)
    }

    return new Promise( async (resolve, reject) => {
        window.FB.login((response) => {
            if (response.status === 'connected') {
                resolve(response)
            }

            reject(response)
        }, {scope: permissions.join(',')})
    })   
}

export const logout = () => {
    return new Promise( (resolve, reject) => {
        window.FB.logout(function(response) {
            resolve(response)
        });
    });   
}

export const link = async (permissions = FB_LINK_PERMISSIONS) => {
    let response = await login(permissions);
    if(response.authResponse.accessToken) {
        response = await client.post(`/user/facebook/link`, { token: response.authResponse.accessToken });
        return (response.status === 200 /* link */)
    }
    return false
}

export const unlink = async (permissions = FB_LINK_PERMISSIONS) => {
    const response = await client.post(`/user/facebook/unlink`)
    return (response.status === 200 /* unlink */)
}

export const catalogs = async () => {
    const response = await client.get(`/user/facebook/catalogs`)
    return response.data.data
}

export const linkCatalog = async (catalog_id)  => {
    const response = await client.post(`/user/facebook/catalogs/${catalog_id}/feeds`)
    return response.data.data
}

export const unlinkCatalog = async ()  => {
    const response = await client.post(`/user/facebook/catalog/unlink`)
    return (response.status === 200 /* unlink */)
}

export const catalog = async ()  => {
    const response = await client.get(`/user/facebook/catalog`)
    return response.data.data
}

export const upload = async ()  => {
    const response = await client.post(`/user/facebook/catalog/upload`)
    return (response.status === 200 /* upload */)
}