import { client } from '../client'

export const all = async () => {
    const response = await client.get(`/categories`);
    return response.data.data
}

export const getChilds = async (id) => {
    const response = await client.get(`/categories/${id}`);
    return response.data.data
}
