import React from 'react';

import { useContext } from 'hooks'
import { Title, Titlebar, Loading } from 'components'
import { IonApp, IonPage, IonContent } from '@ionic/react';

export const App = ({ title, titlebar, onBack, onButton, icon, backButton = true, children, transparent = false}) => {
    
    const { onContentScroll } = useContext()

    return (
        <>
            <Loading />
            <Title>{title}</Title>
            <IonContent
                scrollEvents={true}
                onIonScroll={(e) => onContentScroll.next(e)}
            >
                <section className="App-Wrapper">
                    {
                        titlebar &&
                        <Titlebar title={title}
                            onBack={onBack}
                            backButton={backButton}
                            onButton={onButton}
                            icon={icon}
                            transparent={transparent}
                        />
                    }
                    {children}
                </section>
            </IonContent>
        </>
    )
}
