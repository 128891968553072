import React, { useRef, forwardRef, useImperativeHandle } from 'react';

export const InputFile = forwardRef(({ children, render, onFiles, multiple = true}, ref) => {
    const input = useRef();

    useImperativeHandle(ref, () => ({
        open: handleClick
    }));

    const handleClick = () => {
        input.current && input.current.click();
    }

    const handleChange = () => {
        onFiles && input.current.files && onFiles(input.current.files);
    }

    return (
        <>
            <input
                style={{
                    width: '0.1px',
                    height: '0.1px',
                    opacity: 0,
                    overflow: 'hidden',
                    position: 'absolute',
                    zIndex: -1
                }}
                multiple={multiple}
                type="file"
                ref={input}
                onChange={handleChange}
                accept="image/png, image/jpeg, image/bmp"
            />
            {(render && render({ onClick: handleClick })) || children}
        </>
    );
})
