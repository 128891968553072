import React, { useEffect, useRef, useState } from 'react';

import { useContext } from 'hooks'
import { useHistory } from 'react-router';

export const Titlebar = ({ title, backButton = true, icon, onBack, onButton, transparent = false }) => {
    const $ = window.jQuery;

    const { onContentScroll } = useContext()
    const [hide, setHide] = useState(false)
    const history = useHistory()

    let elHeader = useRef();
    let elSlider = useRef();

    useEffect(() => {
        const subscribe = onContentScroll.subscribe({
            next: (e) => {
                if (e.detail.currentY > 80) {
                    setHide(true);
                }
                if (e.detail.currentY < 80) {
                    setHide(false);
                }
            }
        });

        return () => subscribe && subscribe.unsubscribe();
    })

    const handleBack = (event) => {
        event.preventDefault()
        onBack ? onBack(event) : history.goBack();
    }

    const handleButton = (event) => {
        event.preventDefault()
        onButton && onButton(event)
    }

    return (
        <header className={`App-Header wow fadeInDown ${hide ? 'HeaderHide' : ''}`} data-wow-duration="0.6s" data-wow-delay="0.5s" ref={elHeader}>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 p-0">
                        <div className={`BoxHeader ${transparent ? 'BgTransparent' : ''}`}>
                            {
                                backButton && <a className="BoxHeader-LinkBack" href="#" onClick={handleBack}>
                                    <i className="fa fa-angle-left"></i>
                                </a>
                            }
                            {
                                !transparent && <>
                                    <div className="BoxHeader-Text">
                                        <p className="TextSeccion FontTitle1">{title}</p>
                                    </div>
                                    {
                                        icon && <a className="BoxHeader-BoxAvisos App-Button-Tilte coler-text--primary" href="#" onClick={handleButton}>
                                            <i className={`fa fa-${icon}`}></i>
                                        </a>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}
