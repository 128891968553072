import React, { useState, useEffect } from 'react'
import { useHistory, Link } from "react-router-dom"

import { IonLoading, IonAlert } from '@ionic/react'

import { UserService, AuthService } from 'services'

import { App, Content, Spinner, Input, Button } from 'components'

export const EditProfile = () => {

    const history = useHistory()

    const [errors, setErrors] = useState({})

    const [showAlertError, setShowAlertError] = useState(false)
    const [alertMessage, setAlertMessage] = useState()
    const [submitting, setSubmitting] = useState(false)
    const [sendResetPasswordAlert, setSendResetPasswordAlert] = useState(false)

    const [user, setUser] = useState()
    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()
    const [phone, setPhone] = useState()
    const [valid, setValid] = useState(false)


    const [password, setPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('')

    useEffect(() => {
        const user = history.location.state.user
        setUser(user)
        setFirstName(user.first_name)
        setLastName(user.last_name)
        setPhone(user.phone)
    }, [])


    useEffect(() => {
        let valid = user ? (
            user.first_name != firstName ||
            user.last_name != lastName ||
            user.phone != phone) : true

        setValid(
            firstName && firstName.trim() != '' &&
            lastName && lastName.trim() != '' &&
            phone && phone.trim() != '' && valid
        )
    }, [user, firstName, lastName, phone])

    const updatePerfile = async () => {
        setSubmitting(true)
        setAlertMessage()

        try {
            setErrors({})
            let user = await UserService.update({
                first_name: firstName,
                last_name: lastName,
                phone: phone
            })

            history.replace('/profile', { user })
        } catch (error) {
            if (error?.response?.data?.errors) {
                setErrors(error?.response?.data?.errors)
            } else {
                setShowAlertError(true)
            }
        }

        setSubmitting(false)
    }

    const resetPassword = async () => {
        setSubmitting(true)
        setAlertMessage()

        try {
            setErrors({})
            let result = await UserService.resetPassword({
                password: user.has_password ? password : null,
                new_password: newPassword,
                new_password_confirmation: newPasswordConfirmation
            })

            if (result) {
                setPassword('')
                setNewPassword('')
                setNewPasswordConfirmation('')

                history.replace('/profile', {
                    user: {
                        ...user,
                        has_password: true
                    }
                })
            }
        } catch (error) {
            if (error?.response?.data?.errors) {
                setErrors(error?.response?.data?.errors)
            } else if (error?.response?.data?.message) {
                setAlertMessage(error?.response?.data?.message)
                setShowAlertError(true)
            } else {
                setShowAlertError(true)
            }
        }

        setSubmitting(false)
    }

    const forgotPassword = async () => {
        setSubmitting(true)
        setAlertMessage()

        try {
            setErrors({})
            let result = await AuthService.forgotPassword(user.email)
            setSendResetPasswordAlert(result)
        } catch (error) {
            if (error?.response?.data?.errors) {
                setAlertMessage(error?.response?.data?.errors['email'])
                setShowAlertError(true)
            } else if (error?.response?.data?.message) {
                setAlertMessage(error?.response?.data?.message)
                setShowAlertError(true)
            } else {
                setShowAlertError(true)
            }
        }

        setSubmitting(false)
    }

    return (
        <App title="Mi cuenta" titlebar onBack={() => history.goBack()}>
            <Content margin>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <article className="wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.5s">
                            {!user ? <Spinner /> :
                                <div className="App-MiCuenta-DatosFormularios mt-4">
                                    <div className="App-MiCuenta-DatosFormularios-Box mb-4 wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.5s">
                                        <h2 className="Title FontTitle1 mb-3">Datos personales</h2>
                                        <form className="FormularioLogin FormularioBase" action="#">
                                            <Input type="text"
                                                placeholder="Nombre"
                                                value={firstName}
                                                onChange={e => setFirstName(e.target.value)}
                                                errors={errors['first_name']}
                                                disabled={submitting}
                                            />
                                            <Input type="text"
                                                placeholder="Apellido"
                                                value={lastName}
                                                onChange={e => setLastName(e.target.value)}
                                                errors={errors['last_name']}
                                                disabled={submitting}
                                            />
                                            <Input type="text"
                                                placeholder="Teléfono"
                                                value={phone}
                                                onChange={e => setPhone(e.target.value)}
                                                errors={errors['phone']}
                                                disabled={submitting}
                                            />
                                            <Button onClick={() => updatePerfile()} color="primary" disabled={!valid || submitting}>
                                                Guardar
                                            </Button>
                                        </form>
                                    </div>
                                    {
                                        user.has_password
                                            ? <div className="App-MiCuenta-DatosFormularios-Box mb-4 wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.5s">
                                                <h2 className="Title FontTitle1 mb-3">Cambiar contraseña</h2>
                                                <form className="FormularioLogin FormularioBase" action="#">
                                                    <Input type="password"
                                                        placeholder="Contraseña actual"
                                                        value={password}
                                                        onChange={e => setPassword(e.target.value)}
                                                        errors={errors['password']}
                                                        disabled={submitting}
                                                    />
                                                    <Input type="password"
                                                        placeholder="Contraseña nueva"
                                                        value={newPassword}
                                                        onChange={e => setNewPassword(e.target.value)}
                                                        errors={errors['new_password']}
                                                        disabled={submitting}
                                                    />
                                                    <Input type="password"
                                                        placeholder="Confirme la contraseña nueva"
                                                        value={newPasswordConfirmation}
                                                        onChange={e => setNewPasswordConfirmation(e.target.value)}
                                                        errors={errors['new_password_confirmation']}
                                                        disabled={submitting}
                                                    />
                                                    <Button onClick={() => resetPassword()} color="primary" disabled={submitting}>
                                                        Cambiar contraseña
                                                    </Button>
                                                    <Link className="Link forgot-password" to="/forgot/password" disabled={submitting}>¿Olvide mi contraseña?</Link>
                                                </form>
                                            </div>
                                            : <div className="App-MiCuenta-DatosFormularios-Box mb-4 wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.5s">
                                                <h2 className="Title FontTitle1 mb-3">Establecer contraseña</h2>
                                                <form className="FormularioLogin FormularioBase" action="#">
                                                    <Input type="password"
                                                        placeholder="Contraseña"
                                                        value={newPassword}
                                                        onChange={e => setNewPassword(e.target.value)}
                                                        errors={errors['new_password']}
                                                        disabled={submitting}
                                                    />
                                                    <Input type="password"
                                                        placeholder="Confirme la contraseña"
                                                        value={newPasswordConfirmation}
                                                        onChange={e => setNewPasswordConfirmation(e.target.value)}
                                                        errors={errors['new_password_confirmation']}
                                                        disabled={submitting}
                                                    />
                                                    <Button onClick={() => resetPassword()} color="primary" disabled={submitting}>
                                                        Establecer contraseña
                                                    </Button>
                                                </form>
                                            </div>
                                    }
                                </div>
                            }
                        </article>
                    </div>
                </div>
            </Content>
            <IonAlert
                isOpen={showAlertError}
                onDidDismiss={() => setShowAlertError(false)}
                message={alertMessage || 'No se puedo actualizar el perfil'}
                buttons={['Aceptar']}
            />

            <IonAlert
                isOpen={sendResetPasswordAlert}
                onDidDismiss={() => setSendResetPasswordAlert(false)}
                header={'¡Recordatorio de contraseña enviado!'}
                message={`Se envió un correo electrónico a ${user?.email} con los paso a seguir para restablecer la contraseña`}
                buttons={['Aceptar']}
                onClick={() => history.replace('/profile', { user })}
            />

            <IonLoading
                isOpen={submitting}
                backdropDismiss={false}
                keyboardClose={false}
                message={'Espere por favor...'}
                duration={5000}
            />
        </App>
    )
}

