import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from "react-router-dom"

import {
    IonContent,
    IonPage,
    IonTitle,
    IonToolbar,
    IonBackButton,
    IonButton,
    IonButtons,
    IonItem,
    IonInput,
    IonIcon,
    IonText,
    IonLoading,
    IonAlert
} from '@ionic/react'

import { closeCircleOutline } from 'ionicons/icons';

import { BankAccountService, DocumentTypeService, BankService, CurrencyService } from 'services'

import { App, Content, Input, Spinner, Button, Image, Select } from 'components'

export const EditBankAccount = () => {

    const history = useHistory()
    const params = useParams()

    const [errors, setErrors] = useState({})

    const [showAlertError, setShowAlertError] = useState(false);
    const [submitting, setSubmitting] = useState(false)
    const [loading, setLoading] = useState(true)

    const [banks, setBanks] = useState([])
    const [currencies, setCurrencies] = useState([])
    const [documentTypes, setDocumentTypes] = useState([])

    const [bankAccount, setBankAccount] = useState()
    const [name, setName] = useState()
    const [bank, setBank] = useState()
    const [currency, setCurrency] = useState()
    const [account, setAccount] = useState()
    const [cbu, setCBU] = useState()
    const [alias, setAlias] = useState()
    const [documentType, setDocumentType] = useState()
    const [document, setDocument] = useState()
    const [valid, setValid] = useState(false)

    const load = async () => {
        let [
            banks,
            documents,
            currencies
        ] = await Promise.all([
            BankService.list(),
            DocumentTypeService.list(),
            CurrencyService.list()
        ]);

        setBanks(banks)
        setDocumentTypes(documents)
        setCurrencies(currencies)
        setLoading(false)
    }

    useEffect(() => {        
        load()

        const { bankAccount, create } = history.location?.state;

        if (!bankAccount && !create) {
            history.replace('/profile')
            return;
        }

        if (bankAccount) {
            setBankAccount(bankAccount)
            setName(bankAccount.name)
            setBank(bankAccount.bank.id)
            setAccount(bankAccount.account)
            setCBU(bankAccount.cbu)
            setAlias(bankAccount.alias)
            setDocumentType(bankAccount.document_type.id)
            setDocument(bankAccount.document)
            setCurrency(bankAccount.currency.code)
        }
    }, [])

    useEffect(() => {
        let valid = bankAccount ? (
            // bankAccount.name != name ||
            bankAccount.bank.id != bank ||
            bankAccount.account != account ||
            //bankAccount.alias != alias ||
            //bankAccount.cbu != cbu||
            bankAccount.currency.code != currency ||
            bankAccount.document_type.id != documentType||
            bankAccount.document != document) : true

        setValid(
            //name && name.trim() != '' &&
            bank && bank > 0 &&
            account && account.trim() != '' &&
            //cbu && cbu.trim() != '' && 
            currency && currency.trim() != '' && 
            documentType && documentType > 0 && 
            document && document.trim() != '' && valid
        )
    }, [name, bank, account, cbu, alias, currency, documentType, document])

    const createBanckAccount = async () => {
        setSubmitting(true);

        try {
            let data = {
                name,
                bank_id: bank,
                account,
                cbu,
                alias,
                currency_code: currency,
                document_type_id: documentType,
                document,
            }

            let bankAccount = await (params.id
                ? BankAccountService.update(params.id, data)
                : BankAccountService.create(data))

            history.replace('/profile', { bankAccount });
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.errors) {
                setErrors(error?.response?.data?.errors)
            } else {
                setShowAlertError(true)
            }
        }

        setSubmitting(false);
    }

    return (
        <App title="Datos Bancarios" titlebar onBack={() => history.goBack()}>
            <Content margin>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <article className="wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.5s">
                            <div className="App-MiCuenta-DatosFormularios mt-4">
                                <div className="App-MiCuenta-DatosFormularios-Box mb-4 wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.5s">
                                    <h2 className="Title FontTitle1 mb-3">Datos Bancarios</h2>
                                    <form className="FormularioLogin FormularioBase" action="#">
                                        <Input
                                            type="text"
                                            value={name}
                                            placeholder="Titular de la cuenta *"
                                            onChange={e => setName(e.target.value)}
                                            disabled={submitting}
                                            errors={errors['name']} />
                                        <Select
                                            empty
                                            placeholder="Banco *"
                                            value={bank}
                                            onChange={e => setBank(e.target.value)}
                                            errors={errors['bank_id']} >
                                            {banks.map((bank) => <option key={bank.id} value={bank.id}>{bank.name}</option>)}
                                        </Select>
                                        <Select
                                            empty
                                            placeholder="Moneda *"
                                            value={currency}
                                            onChange={e => setCurrency(e.target.value)}
                                            errors={errors['currency_code']} >
                                            {
                                                currencies.map((currency) => 
                                                    <option key={currency.id} value={currency.code}>
                                                        {currency.code} - {currency.name}
                                                    </option>
                                                )
                                            }
                                        </Select>
                                        <Input
                                            type="text"
                                            value={account}
                                            placeholder="Número de Cuenta *"
                                            onChange={e => setAccount(e.target.value)}
                                            disabled={submitting}
                                            errors={errors['account']} />
                                        {/*
                                            <Input
                                                type="number"
                                                value={cbu}
                                                placeholder="CBU *"
                                                onChange={e => setCBU(e.target.value)}
                                                disabled={submitting}
                                                errors={errors['cbu']} />
                                        */}
                                        {/*
                                            <Input
                                            type="text"
                                            value={alias}
                                            placeholder="Alias"
                                            onChange={e => setAlias(e.target.value)}
                                            disabled={submitting}
                                         errors={errors['alias']} />
                                        */}
                                        <Select
                                            empty
                                            placeholder="Tipo de documento *"
                                            value={documentType}
                                            onChange={e => setDocumentType(e.target.value)}
                                            errors={errors['document_type_id']} >
                                            {
                                                documentTypes.map((documentType) => 
                                                    <option key={documentType.id} value={documentType.id}>
                                                        {documentType.name}
                                                    </option>
                                                )
                                            }
                                        </Select>
                                        <Input
                                            type="text"
                                            value={document}
                                            placeholder="Documento *"
                                            onChange={e => setDocument(e.target.value)}
                                            disabled={submitting}
                                            errors={errors['document']} />
                                        <Button onClick={() => createBanckAccount()} color="primary" disabled={!valid || submitting}>
                                            Guardar
                                        </Button>
                                    </form>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </Content>
            <IonAlert
                isOpen={showAlertError}
                onDidDismiss={() => setShowAlertError(false)}
                header={'No se puedo actualizar el perfil'}
                message={'Intente nuevamente mas tarde'}
                buttons={['Aceptar']}
            />

            <IonLoading
                isOpen={loading || submitting}
                backdropDismiss={false}
                keyboardClose={false}
                message={'Espere por favor...'}
                duration={5000}
            />
        </App>

    )
}

