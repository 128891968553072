import { client } from '../client'

export const list = async () => {
    const response = await client.get(`/user/stores`);
    return response.data.data
}

export const detail = async (id) => {
    const response = await client.get(`/user/stores/${id}`);
    return response.data.data
}

export const create = async (store) => {
    const response = await client.post(`/user/stores`, store);
    return response.data.data
}

export const update = async (id, store) => {
    const response = await client.put(`/user/stores/${id}`, store);
    return response.data.data
}

export const remove = async (id) => {
    const response = await client.delete(`/user/stores/${id}`);
    return response.data.data
}
