import { client } from '../client'

export const list = async () => {
    const response = await client.get(`/currencies`);
    return response.data.data
}

export const detail = async (id) => {
    const response = await client.get(`/currencies/${id}`);
    return response.data.data
}