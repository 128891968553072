import { client } from './../client'

export const user = async () => {
    const response = await client.get(`/user`);
    return response.data.data;
}

export const update = async (params) => {
    const response = await client.put(`/user`, params);
    return response.data.data;
}

export const resetPassword = async (params) => {
    const response = await client.post(`/user/reset-password`, params);
    return (response.status === 200 /* Reset */)
}

export const statistics = async () => {
    const response = await client.get(`/user/statistics`);
    return response.data.data;
}

export const unsubscriber = async (provider) => {
    const response = await client.post(`/user/notifications/${provider}/unsubscriber`);
    return (response.status === 200 /* Unsubscriber */)
}

export const subscriber = async (provider, token) => {
    const response = await client.post(`/user/notifications/${provider}/subscriber`, { token });
    return (response.status === 200 /* Subscriber */)
}