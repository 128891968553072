import React from 'react';

import { Helmet } from "react-helmet";

import config from '../config'

export const Title = ({ children, title }) => {

    title = title || children

    if (Array.isArray(title))
        title = title.join(' ')

    return (
        <Helmet>
            {
                title
                    ? <title>{title} - {config.title}</title>
                    : <title>{config.title}</title>
            }
        </Helmet>
    )
}
