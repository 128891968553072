import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from "react-router-dom"

import { Plugins } from '@capacitor/core';

import { ProductService } from 'services'

import { App, Content, Carousel, CarouselImage, Button } from 'components'

import { currencyFormat } from 'common'
import { IonRefresherContent, IonRefresher, IonLoading, IonAlert } from '@ionic/react';

import config from 'config'

const { Share, Browser } = Plugins;

export const DetailProduct = () => {
    const $ = window.jQuery

    const history = useHistory()
    const params = useParams()
    let refDetail = useRef()
    const refresh = useRef()

    const [showConfirm, setShowConfirm] = useState(false);
    const [loading, setLoading] = useState()

    const [product, setProduct] = useState()
    const [showDetail, setShowDetail] = useState(false)
    const [showLimitDetail, setShowLimitDetail] = useState(false)

    const loadProduct = async () => {
        try {
            let product = await ProductService.detail(params.id)
            setProduct(product)
        } catch (error) {
            console.error(error)
        }

        refresh.current && refresh.current.complete()

    }

    const openInBrowser = async () => {
        await Browser.open({ url: product.url });
    }

    const changeState = async (product) => {
        setLoading(true)
        try {
            let { id, status } = product
            if (status === 'paused') {
                await ProductService.publish(id)
                product.status = 'published'
            } else {
                await ProductService.pause(id)
                product.status = 'paused'
            }

            setProduct(product)
        } catch (error) {
            console.error(error)
        }
        setLoading(false)
    }

    const share = async () => {
        await Share.share({
            title: product.name,
            text: product.detail,
            url: product.url,
            dialogTitle: 'Compartir Producto'
        });
    }

    const remove = async () => {
        setLoading(true)
        try {
            await ProductService.remove(product.id)
            history.replace('/products')
        } catch (error) {
            console.error(error)
        }
        setLoading(false)
    }

    const toggleDetail = (event) => {
        event.preventDefault()
        setShowDetail(!showDetail);
    }

    useEffect(() => {
        setProduct(history.location?.state?.product)
        loadProduct()
    }, [])

    const handleShowLimitDetail = (ref) => {
        if (ref && ref.clientHeight >= 150) {
            setShowLimitDetail(true)
        }
    }

    return (
        <App title={product?.name || 'Detalle del artículo'} titlebar icon="pencil" onButton={() => history.push(`/products/${params.id}/edit`)}>
            <Content margin className="App-Articulo">
                <IonRefresher slot="fixed" onIonRefresh={() => loadProduct()} ref={refresh}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 p-0">
                        <article className="App-Articulo wow fadeInUp">
                            {
                                product && <>
                                    <Carousel>
                                        {product.gallery.map((photo, index) => <CarouselImage key={index} path={photo} />)}
                                    </Carousel>
                                    <div className="App-Articulo-Detalle px-3">
                                        <div className="App-Brands mb-2">
                                            {
                                               ( product.user?.shopping && product.user?.shopping.logo )
                                                ? <img className="ImgLogo" src={`${config.url}/storage/${product.user.shopping.logo}`} alt={product.user.shopping.name} />
                                                : <img className="ImgLogo" src="assets/img/storeados-isologo-rose.png" alt="Storeados" />
                                            }
                                        </div>
                                        <div className="App-Articulo-Detalle-Header">
                                            <div className="BoxTitle">
                                                <h2 className="Title FontTitle1">{product?.name || 'Detalle del artículo'}</h2>
                                                <p className="Categorias"><span className="Categoria">{product.category?.category?.name}</span><span className="Categoria">{product.category.name}</span></p>
                                            </div>
                                            
                                            <span className="Numero BgVioleta BorderAll">{currencyFormat(product.price)}</span>
                                        </div>
                                        <div className="App-Articulo-Detalle-Descripcion">
                                            <h2 className="Title FontTitle1">Descripción</h2>
                                            <div className={`BoxText ${showDetail ? 'ShowText' : ''}`} ref={(ref) => handleShowLimitDetail(ref)}>
                                                <p className="Text">{product.detail}</p>
                                            </div>
                                            {
                                                showLimitDetail && <div className="w-100 text-center">
                                                    <a className="LinkVerMas BorderAll" onClick={toggleDetail} href="#">{!showDetail ? 'Ver más' : 'Ocultar'}
                                                        <i className={`fa fa-angle-${!showDetail ? 'down' : 'up'}`}></i>
                                                    </a>
                                                </div>
                                            }
                                        </div>
                                        <Button onClick={() => share()} color="primary">Compartir</Button>
                                        <Button onClick={() => openInBrowser()} color="primary">Ver Publicacion</Button>
                                        {
                                            product.status == 'paused'
                                                ? <Button onClick={() => changeState({...product})} color="primary">Publicar</Button>
                                                : <Button onClick={() => changeState({...product})} color="primary">Pausar</Button>
                                        }
                                        
                                        <Button onClick={() => setShowConfirm(true)} color="danger">Eliminar</Button>
                                        
                                    </div>
                                </>
                            }
                        </article>
                    </div>
                </div>
            </Content>

            <IonLoading
                isOpen={loading}
                backdropDismiss={false}
                keyboardClose={false}
                message={'Cargando...'}
            />
            
            <IonAlert
                isOpen={showConfirm}
                onDidDismiss={() => setShowConfirm(false)}
                header={'Eliminar'}
                message={'¿Esta seguro que quiere eliminar el producto?'}
                buttons={[
                    {
                        text: 'Cancelar',
                        role: 'cancel',
                        cssClass: 'secondary'
                    },
                    {
                        text: 'Aceptar',
                        handler: () => remove()
                    }
                ]}
            />

        </App>
    )
}