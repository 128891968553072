import { client } from '../client'

export const all = async () => {
    const response = await client.get(`/delivery-methods`);
    return response.data.data
}

export const detail = async (id) => {
    const response = await client.get(`/delivery-methods/${id}`);
    return response.data.data
}