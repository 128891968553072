import React, { useEffect, useRef } from 'react';

import loading from "assets/img/precarga/loading.gif";

export const Loading = () => {
    const $ = window.jQuery
    const refPreloader = useRef()
    const refStatus = useRef()

    useEffect(() => {
        $(refPreloader.current).fadeOut();
        $(refStatus.current).delay(450).fadeOut("slow");
    }, [])

    return (
        <div id="preloader" ref={refPreloader}>
            <div id="status" ref={refStatus}>
                <img src={loading} />
            </div>
        </div>
    )
}
