import React, { useEffect, useRef } from 'react';

export const InputSearch = ({ icon = 'search', onClick, ...rest }) => {

    const handleClick = (event) => {
        event.preventDefault()
        onClick && onClick(event)
    }

    return (
        <div className="App-Articulos-Search">
            <form className="FormularioLogin FormularioBase">
                <div className="form-group BoxSeach">
                    <input type="text" className={`Input BorderAll form-control`} {...rest} />
                    {
                        icon &&
                        <a className="BtnSearch" href="#" onClick={handleClick}>
                            <i className={`fa fa-${icon}`}></i>
                        </a>
                    }
                </div>
            </form>
        </div>
    )
}
