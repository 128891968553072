import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from "react-router-dom"

import { IonLoading, IonAlert } from '@ionic/react'

import { StoreService, CountryService, StateService, Service } from 'services'

import { App, Content, Input, Spinner, Button, Image, Select } from 'components'

import { Plugins } from '@capacitor/core';

const { Geolocation } = Plugins;

export const EditStore = () => {

    const history = useHistory()
    const params = useParams()

    const [errors, setErrors] = useState({})

    const [showAlertError, setShowAlertError] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [loading, setLoading] = useState(true)

    const [countries] = useState([CountryService.DEFAULT_COUNTRY])
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])

    const [store, setStore] = useState()

    const [name, setName] = useState()
    const [country, setCountry] = useState(1)
    const [state, setState] = useState()
    const [city, setCity] = useState()
    const [streetName, setStreetName] = useState()
    const [streetNameAlt, setStreetNameAlt] = useState()
    const [betweenStreets, setBetweenStreets] = useState()
    const [streetNumber, setStreetNumber] = useState()
    const [district, setDistrict] = useState()
    const [buildingName, setBuildingName] = useState()
    const [zipCode, setZipCode] = useState()
    const [reference, setReference] = useState()
    const [valid, setValid] = useState(false)

    const [latitude, setLat] = useState()
    const [longitude, setLong] = useState()

    const loadPositionGPS = async () => {
        try {
            let position = history.location?.state?.location
            
            if(!position) {
                position = await Geolocation.getCurrentPosition();
                position = {
                    lat: position.coords.latitude,
                    long: position.coords.longitude
                }
            } else {
                position = {
                    lat: position.lat,
                    long: position.lng
                }
            }

            let data = await Service.location(position)

            setLat(position.lat)
            setLong(position.long) 
            if(data) {
                setZipCode(data.zip_code);
                setStreetName(data.street_name)
                setStreetNumber(data.address_number)
                setState(data.state_id)
                setCity(data.city_id)

                let cities = await StateService.cities(data.state_id)
                setCities(cities)
            }            

            console.log(data)
        } catch (error) {
            console.log(error);
        }
    }

    const load = async (store) => {
        let states = await CountryService.states(CountryService.DEFAULT_COUNTRY.id)
        setStates(states)
        
        if(store) {
            setState(store.state.id)
            let cities = await StateService.cities(store.state.id)
            setCities(cities)
            
            if(store.city.id) {
                setCity(store.city.id)
            }
        }
        await loadPositionGPS()
        setLoading(false)
    }

    const loadCities = async () => {
        let cities = await StateService.cities(state)
        setCities(cities)
        setCity(null)
    }

    useEffect(() => {
        const { store, create } = history.location?.state

        if (!store && !create) {
            history.replace('/profile')
            return
        }

        if (store) {
            setStore(store)
            setName(store.name)
            setCountry(store.country.id)
            setState(store.state.id)
            setCity(store.city.id)
            setStreetName(store.street_name)
            setStreetNameAlt(store.street_name_alt)
            setBetweenStreets(store.between_streets)
            setStreetNumber(store.street_number)
            setDistrict(store.district)
            setBuildingName(store.building_name)
            setZipCode(store.zip_code)
            setReference(store.reference)            
        }

        load(store)

    }, [])
    
    useEffect(() => {
        !loading && state && loadCities()
    }, [state])

    useEffect(() => {
        let valid = store ? (
            store.name != name ||
            store.street_name != streetName ||
            store.street_name_alt != streetNameAlt ||
            store.city.id != city ||
           // store.between_streets != betweenStreets ||
            store.street_number != streetNumber ||
            store.district != district ||
            store.building_name != buildingName ||
           // store.zip_code != zipCode ||
            store.reference != reference) : true
        
        setValid(
            name && name.trim() != '' &&
            streetName && streetName.trim() != '' &&
            district && district.trim() != '' &&
            city && city > 0 &&
            //streetNameAlt && streetNameAlt.trim() != '' &&
            //betweenStreets && betweenStreets.trim() != '' &&
            streetNumber && streetNumber > 0 &&
            //zipCode && zipCode > 0 &&
            //reference && reference.trim() != '' && 
            valid
        )
    }, [name, streetName, streetNameAlt, country, state, city, betweenStreets, streetNumber, zipCode, district, buildingName, reference])

    const createStore = async () => {
        setSubmitting(true)

        try {
            let data = {
                name,
                city_id: city,
                street_name: streetName,
                street_name_alt: streetNameAlt,
                //between_streets: betweenStreets,
                street_number: streetNumber,
                district,
                building_name: buildingName,
                //zip_code: zipCode,
                reference,
                latitude,
                longitude
            }

            let store = await (params.id
                ? StoreService.update(params.id, data)
                : StoreService.create(data))

            history.replace('/profile', { store })
        } catch (error) {
            console.log(error)
            if (error?.response?.data?.errors) {
                setErrors(error?.response?.data?.errors)
            } else {
                setShowAlertError(true)
            }
        }

        setSubmitting(false)
    }

    return (
        <App title="Ubicación del local" titlebar onBack={() => history.goBack()}>
            <Content margin>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <article className="wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.5s">
                            <div className="App-MiCuenta-DatosFormularios mt-4">
                                <div className="App-MiCuenta-DatosFormularios-Box mb-4 wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.5s">
                                    <h2 className="Title FontTitle1 mb-3">Datos personales</h2>
                                    <form className="FormularioLogin FormularioBase" action="#">
                                        <Input
                                            type="text"
                                            value={name}
                                            placeholder="Nombre del local *"
                                            onChange={e => setName(e.target.value)}
                                            disabled={submitting}
                                            errors={errors['name']}
                                        />
                                        <Select
                                            readOnly
                                            placeholder="Pais *"
                                            value={country}
                                            errors={errors['country_id']} >
                                            {countries.map((country) => <option key={country.id} value={country.id}>{country.name}</option>)}
                                        </Select>
                                        <Select
                                            empty
                                            placeholder="Departamento *"
                                            value={state}
                                            onChange={e => setState(e.target.value)}
                                            errors={errors['state_id']} >
                                            {states.map((state) => <option key={state.id} value={state.id}>{state.name}</option>)}
                                        </Select>
                                        {
                                            cities && cities.length > 0 &&
                                            <Select
                                                empty
                                                placeholder="Ciudad *"
                                                value={city}
                                                onChange={e => setCity(e.target.value)}
                                                errors={errors['city_id']} >
                                                {cities.map((city) => <option key={city.id} value={city.id}>{city.name}</option>)}
                                            </Select>
                                        }
                                        <Input
                                            type="text"
                                            value={district}
                                            placeholder="Barrio *"
                                            onChange={e => setDistrict(e.target.value)}
                                            disabled={submitting}
                                            errors={errors['district']}
                                        />
                                        <Input
                                            type="text"
                                            value={streetName}
                                            placeholder="Calle 1 *"
                                            onChange={e => setStreetName(e.target.value)}
                                            disabled={submitting}
                                            errors={errors['street_name']}
                                        />
                                        <Input
                                            type="text"
                                            value={streetNameAlt}
                                            placeholder="Calle 2"
                                            onChange={e => setStreetNameAlt(e.target.value)}
                                            disabled={submitting}
                                            errors={errors['street_name_alt']}
                                        />
                                        {/*
                                        <Input
                                            type="text"
                                            value={betweenStreets}
                                            placeholder="Entre calles"
                                            onChange={e => setBetweenStreets(e.target.value)}
                                            disabled={submitting}
                                            errors={errors['between_streets']}
                                        />
                                        */}
                                        <Input
                                            type="text"
                                            value={buildingName}
                                            placeholder="Nombre Edificio"
                                            onChange={e => setBuildingName(e.target.value)}
                                            disabled={submitting}
                                            errors={errors['building_name']}
                                        />
                                        <Input
                                            type="number"
                                            value={streetNumber}
                                            placeholder="Número de casa/departamento *"
                                            onChange={e => setStreetNumber(e.target.value)}
                                            disabled={submitting}
                                            errors={errors['street_number']}
                                        />
                                        {/*
                                        <Input
                                            type="text"
                                            value={zipCode}
                                            placeholder="Codigo postal"
                                            onChange={e => setZipCode(e.target.value)}
                                            disabled={submitting}
                                            errors={errors['zip_code']}
                                        />
                                        */}
                                        <Input
                                            type="text"
                                            value={reference}
                                            placeholder="Referencia"
                                            onChange={e => setReference(e.target.value)}
                                            disabled={submitting}
                                            errors={errors['reference']}
                                        />

                                        <Button onClick={() => createStore()} color="primary" disabled={!valid || submitting}>
                                            Guardar
                                        </Button>
                                    </form>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </Content>
            <IonAlert
                isOpen={showAlertError}
                onDidDismiss={() => setShowAlertError(false)}
                header={'No se puedo actualizar el perfil'}
                message={'Intente nuevamente mas tarde'}
                buttons={['Aceptar']}
            />

            <IonLoading
                isOpen={loading || submitting}
                backdropDismiss={false}
                keyboardClose={false}
                message={'Espere por favor...'}
            />

        </App>
    )
}

