import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from "react-router-dom"

import { IonLoading } from '@ionic/react'

import { App, Content, Maps } from 'components'

export const BuyLocation = () => {

    const history = useHistory()
    const params = useParams()
    const map = useRef()

    const next = (location) => {
        history.push(`/${params.id}-${params.slug}/delivery`, {...history.location.state, location})
    }

    return (
        <App title="Ubicacion de entrega" titlebar icon="crosshairs" onButton={() => map.current.centerGPSLocation() } onBack={ () => history.replace(`/${params.id}-${params.slug}/payment`, {...history.location.state })}>
            <Content messages={false}>
                <Maps 
                autolocation={!history.location.state?.store}
                marker={ history.location.state?.store && {
                    lat: history.location.state?.store.latitude,
                    lng: history.location.state?.store.longitude
                }} 
                location={ history.location.state?.store && {
                    lat: history.location.state?.store.latitude,
                    lng: history.location.state?.store.longitude
                }}
                onContinue={next} ref={map}
            />
            </Content>
        </App>
    )
}

