import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from "react-router-dom"
import {
    IonLoading,
    IonAlert,
    IonGrid,
    IonRow,
    IonCol,
    IonActionSheet
} from '@ionic/react'

import { useCamera } from '@ionic/react-hooks/camera'

import { camera, share } from 'ionicons/icons'

import {
    Plugins,
    Capacitor,
    CameraResultType,
    CameraSource
} from "@capacitor/core"

import {
    ProductService,
    CategoryService,
    DeliveryMethodService
} from 'services'

import {
    App,
    Content,
    InputFile,
    Input,
    TextArea,
    ButtonBoxGradent,
    Button,
    ImageAndDelete,
    Select,
    SelectOptions,
    InputCurrency,
    InputNumber,
    SelectOptionsGroups
} from 'components'

import config from 'config'

const { Share, Browser } = Plugins

let groups = {
    1: 1,
    3: 1
}

export const EditProduct = () => {

    const history = useHistory()
    const params = useParams()

    const inputFile = useRef()

    const { getPhoto } = useCamera()

    const [showActionSheet, setShowActionSheet] = useState(false);

    const [categories, setCategories] = useState([])
    const [subCategories, setSubCategories] = useState([])
    const [deliveryMethods, setDeliveryMethods] = useState([])

    const [name, setName] = useState('')
    const [detail, setDetail] = useState('')
    const [price, setPrice] = useState()
    const [stock, setStock] = useState()
    const [category, setCategory] = useState()
    const [subCategory, setSubCategory] = useState()
    const [deliveryMethod, setDeliveryMethod] = useState([])
    const [gallery, setGallery] = useState([])
    const [photos, setPhotos] = useState([])
    const [product, setProduct] = useState(undefined)

    const [errors, setErrors] = useState({})
    const [showAlertError, setShowAlertError] = useState(false)
    const [alertMessage, setAlertMessage] = useState();
    const [submitting, setSubmitting] = useState(false)
    const [loading, setLoading] = useState(true)
    const [progress, setProgress] = useState(false)
    const [valid, setValid] = useState(false)
    const [changePhotos, setChangePhotos] = useState(false)

    useEffect(() => { load() }, [])
    useEffect(() => { loadSubCategories() }, [category])

    const load = async () => {
        try {
            let [
                product,
                gallery,
                categories,
                deliveryMethods,
            ] = await Promise.all([
                ProductService.detail(params.id),
                ProductService.listPhotos(params.id),
                CategoryService.all(),
                DeliveryMethodService.all()
            ])

            setProduct(product)
            setCategories(categories)
            setDeliveryMethods(deliveryMethods)
            setGallery(gallery.map(photo => ({ ...photo, primary: photo.photo == product.photo })))
            setName(product.name)
            setDetail(product.detail)
            setPrice(product.price)
            setStock(product.stock)
            setDeliveryMethod(product.delivery_methods.map(e => e.id))
            setCategory(product.category?.category?.id)

            if (product.category?.category?.id) {
                let categories = await CategoryService.getChilds(product.category?.category?.id)
                setSubCategories(categories)
                setSubCategory(product.category?.id)
            }
        } catch (error) {
            console.error(error)
        }

        setLoading(false)
    }

    const onUploading = (e) => {
        console.log("onUploading", JSON.stringify(e));
        setProgress(e.loaded / e.total)
    }

    const save = async () => {
        setSubmitting(true)

        try {
            let data = {
                name,
                detail,
                price,
                stock,
                delivery_methods: deliveryMethod,
                category_id: subCategory
            }

            let photo_id = null;
            let galleries = gallery.filter(photo => photo.primary);
            if (galleries.length > 0) {
                photo_id = galleries[0].id;
            }

            await ProductService.updateAndPhotosUpload(
                params.id,
                data,
                photos,
                photo_id,
                onUploading
            )

            await Promise.all(
                gallery.filter(photo => photo.delete)
                    .map(photo => ProductService.deletePhoto(product.id, photo.id))
            )

            setSubmitting(false)
            history.replace('/');
            return

        } catch (error) {
            console.log(error)
            if (error?.response?.data?.errors) {
                setErrors(error?.response?.data?.errors)
            } else {
                setAlertMessage(error?.response?.data?.message)
                setShowAlertError(true)
            }
        }

        setSubmitting(false)
    }

    const includes = (array1, array2) => {
        if (array1.length != array2.length)
            return false

        for (let key of array1) {
            if (!array2.includes(key)) {
                return false
            }
        }

        return true
    }

    useEffect(() => {
        setValid(
            name.trim() !== '' &&
            category > 0 &&
            subCategory > 0 &&
            detail.trim() !== '' &&
            price > 0 &&
            stock > 0 &&
            deliveryMethod.length > 0 && (
                product.name != name ||
                product.category.id != subCategory ||
                product.detail != detail ||
                product.price != price ||
                product.stock != stock ||
                !includes(deliveryMethod, product.delivery_methods.map(d => d.id)) ||
                changePhotos
            )
        )
    }, [name, category, subCategory, detail, price, stock, deliveryMethod, photos, changePhotos])

    const loadSubCategories = async () => {
        try {
            if (category && product.category?.category?.id != category) {
                let categories = await CategoryService.getChilds(category)

                setSubCategory(null)
                setSubCategories(categories)
            }
        } catch (error) {
            // Error
        }
    }

    const takePicture = async () => {
        const photo = await getPhoto({
            quality: 100,
            resultType: CameraResultType.Uri,
            source: CameraSource.Prompt,
        })

        let response = await fetch(photo.webPath)

        let count = gallery.filter(p => !p.delete)

        setPhotos([...photos, {
            url: photo.webPath,
            blob: await response.blob(),
            primary: !count.length && (!photos || !photos.length)
        }].splice(0, config.max_photos - count.length))

        setLoading(true)
        setChangePhotos(true)
    }

    const addPhotos = (files) => {
        files = [...files].map(file => ({
            url: URL.createObjectURL(file),
            blob: file,
            primary: false
        }))

        let count = gallery.filter(p => !p.delete)

        if ((!count.length && (!photos || !photos.length)) && files[0]) {
            files[0].primary = true
        }

        setPhotos([...photos, ...files].splice(0, config.max_photos - count.length))
        setChangePhotos(true)
    }

    const deleteGalleryPhoto = async (photo, index) => {
        let primary = photo.primary

        let files = gallery.map(p => (photo.id == p.id) ? { ...p, delete: true, primary: false } : p)

        setGallery(files)

        if (primary) {
            autoSelectPrimary(files, photos)
        }

        setChangePhotos(true)
    }

    const deletePhoto = (index) => {
        let primary = photos[index].primary

        let files = photos.filter((_, i) => index != i);

        setPhotos(files)

        if (primary) {
            autoSelectPrimary(gallery, files)
        }

        setChangePhotos(true)
    }

    const autoSelectPrimary = (galleries, photos) => {
        let count = galleries.filter(photo => !photo.delete)

        if (count && count.length) {
            let p = [...galleries].map(photo => ({ ...photo, primary: false }))
            let index = p.findIndex(photo => !photo.delete)
            p[index].primary = true
            setGallery(p)
        } else if (photos && photos.length && photos[0]) {
            let p = [...photos].map(photo => ({ ...photo, primary: false }))
            p[0].primary = true
            setPhotos(p)
        }
    }

    const setPrimaryPhoto = (index) => {
        setPhotos(photos.map((photo, i) => ({ ...photo, primary: i == index })))
        setGallery(gallery.map(photo => ({ ...photo, primary: false })))
        setChangePhotos(true)
    }

    const setPrimaryGallery = (index) => {
        setGallery(gallery.map((photo, i) => ({ ...photo, primary: i == index })))
        setPhotos(photos.map(photo => ({ ...photo, primary: false })))
        setChangePhotos(true)
    }


    const icons = {
        1: 'motorcycle',
        2: 'handshake-o',
        3: 'motorcycle'
    }

    return (
        <App title="Editar Artículo" titlebar /*icon={true ? 'camera' : ''} onButton={e => takePicture()}*/>
            <Content margin className="App-MiCuenta-DatosFormularios">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <article className="App-DatosPago wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.5s">
                            <IonGrid fixed>
                                <IonRow>
                                    {
                                        gallery.filter(photo => !photo.delete).map((photo, index) =>
                                            <IonCol sizeMd="4" sizeSm="6" sizeXl="4" sizeXs="12" key={index}>
                                                <ImageAndDelete
                                                    path={photo.photo}
                                                    onDelete={() => deleteGalleryPhoto(photo, index)}
                                                    onClick={() => setPrimaryGallery(index)}
                                                    check={photo.primary} />
                                            </IonCol>
                                        )
                                    }

                                    {
                                        photos.map((photo, index) =>
                                            <IonCol sizeMd="4" sizeSm="6" sizeXl="4" sizeXs="12" key={index}>
                                                <ImageAndDelete
                                                    src={photo.url}
                                                    onError={() => setLoading(false)}
                                                    onDidLoad={() => setLoading(false)}
                                                    onDelete={() => deletePhoto(index)}
                                                    onClick={() => setPrimaryPhoto(index)}
                                                    check={photo.primary} />
                                            </IonCol>
                                        )
                                    }

                                </IonRow>
                            </IonGrid>

                            <InputFile onFiles={addPhotos} ref={inputFile} />

                            {   
                                (gallery.filter(p => !p.delete).length + photos.length) < config.max_photos &&
                                <div className="App-DatosPago-Forma App-DatosPago-Fotos">
                                    <ButtonBoxGradent onClick={() => setShowActionSheet(true)}>Agregar Fotos</ButtonBoxGradent>
                                    <p className="Text"><strong>Fotos {(gallery.filter(p => !p.delete).length + photos.length)}/{config.max_photos}:</strong> Cargue las fotos del producto y elige la foto principal del anuncio.</p>
                                </div>
                            }

                            <div className="App-MiCuenta-DatosFormularios mt-4">
                                <div className="App-MiCuenta-DatosFormularios-Box mb-4 wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.5s">
                                    <form className="FormularioLogin FormularioBase" action="#">
                                        <Input
                                            type="text"
                                            value={name}
                                            placeholder="Titulo *"
                                            onChange={e => setName(e.target.value)}
                                            disabled={submitting}
                                            errors={errors['name']} />

                                        <Select
                                            empty
                                            placeholder="Categoria *"
                                            value={category}
                                            onChange={e => setCategory(e.target.value)}
                                            disabled={!categories.length}>
                                            {categories.map(category => <option key={category.id} value={category.id}>{category.name}</option>)}
                                        </Select>

                                        {
                                            category && subCategories.length > 0 && <Select
                                                empty
                                                placeholder="Sub Categoria *"
                                                errors={errors['category_id']}
                                                value={subCategory}
                                                onChange={e => setSubCategory(e.target.value)}
                                                disabled={!subCategories.length}>
                                                {subCategories.map(category => <option key={category.id} value={category.id}>{category.name}</option>)}
                                            </Select>
                                        }

                                        <TextArea
                                            type="text"
                                            value={detail}
                                            placeholder="Descripción adicional *"
                                            onChange={e => setDetail(e.target.value)}
                                            disabled={submitting}
                                            errors={errors['detail']}
                                            rows={10}
                                            cols={30} />

                                        <InputCurrency
                                            value={price}
                                            placeholder="Precio *"
                                            onValueChange ={data => setPrice(data.value)}
                                            disabled={submitting}
                                            errors={errors['price']} />

                                        <InputNumber
                                            value={stock}
                                            placeholder="Stock *"
                                            onValueChange={data => setStock(data.value)}
                                            disabled={submitting}
                                            errors={errors['stock']} />

                                        <SelectOptionsGroups
                                            multiple
                                            title="Formas de Entrega"
                                            icons={icons}
                                            value={deliveryMethod}
                                            options={deliveryMethods.map(delivery => ({
                                                ...delivery,
                                                group: groups[delivery.id]
                                            }))}
                                            onChange={value => setDeliveryMethod(value)}
                                            errors={errors['delivery_methods']} />

                                        <Button mt={3} onClick={() => save()} color="primary" disabled={!valid || submitting}>Guardar cambios</Button>
                                    </form>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </Content>

            <IonActionSheet
                isOpen={showActionSheet}
                onDidDismiss={() => setShowActionSheet(false)}
                buttons={[
                    {
                        text: 'Camara',
                        role: 'destructive',
                        icon: camera,
                        handler: () => {
                            takePicture()
                        }
                    }, {
                        text: 'Galeria de fotos',
                        icon: share,
                        handler: () => {
                            inputFile.current.open()
                        }
                    }
                ]}
            >
            </IonActionSheet>

            <IonAlert
                isOpen={showAlertError}
                onDidDismiss={() => setShowAlertError(false)}
                header={'No se puedo actualizar el producto'}
                message={alertMessage || 'Intente nuevamente mas tarde'}
                buttons={['Aceptar']}
            />

            <IonLoading
                isOpen={loading}
                backdropDismiss={false}
                keyboardClose={false}
                message={'Cargando...'}
                duration={5000}
            />

            <IonLoading
                isOpen={submitting}
                backdropDismiss={false}
                keyboardClose={false}
                message={progress || 'Espere por favor...'}
            />

        </App>
    )
}