const config = {
    title: 'STOREADOS',
    currency: 'PYG',
    decimal: 0,
    max_photos: 4,
    locale: 'es-PY',
//  url: 'http://localhost',            // Localhost
//  url: 'http://192.   .100.5',        // Localhost
//  url: 'http://storepy.com',          // Localhost
//  url: 'http://192.168.10.10',        // Red Privada
//  url: 'http://172.18.91.49',         // Red Publica Seba
//  url: 'http://192.168.100.123',      // Red Publica Juan
//  url: 'https://storepy.com',         // Red Publica Juan
    url: 'https://storepy.lab5411.com', // Produccion
    version: 1,
    client_id: 1,
    client_secret: '8Gy9WfJIwZV3dFg8L6AUzPNKbsEP8F3A2gWLrWz2',
    bancard_sanbox: false,
    google_client_id: '757953889599-p23ecpm1jfnjcc94nf95mcikin9limls.apps.googleusercontent.com',    
    facebook_client_id: '2793397824042187',
    google_maps_key: 'AIzaSyDuyShWE92t-mmv9L_hgYfeYv04K2YwUmM'
}

export default config