import { client } from '../client'

export const listByUser = async ({ page = 1, query }) => {
    const response = await client.get(`/user/products`, {
        params: {
            page,
            query
        }
    });
    return response.data
}

export const list = async ({ page = 1, query }) => {
    const response = await client.get(`/products`, {
        params: {
            page,
            query
        }
    });
    return response.data
}

export const detail = async (id) => {
    const response = await client.get(`/products/${id}`);
    return response.data.data
}

export const create = async (product) => {
    const response = await client.post(`/products`, product);
    return response.data.data
}

export const createAndPhotosUpload = async (product, photos, onUploadProgress) => {
    let formData = new FormData();
    for (let key of Object.keys(product)) {
        if (Array.isArray(product[key])) {
            product[key].forEach((value, index) => formData.append(key + "["+index+"]", value))
        } else {
            formData.append(key, product[key])
        }
    }

    if (photos && photos.length > 0) {        
        let blobs = photos.filter((photo) => photo.primary).map((photo) => photo.blob)
        formData.append('photo', blobs.shift())
        blobs = photos.filter((photo) => !photo.primary).map((photo) => photo.blob)
        blobs.forEach((blob, index) => formData.append('photos['+index+']', blob))
    }

    const response = await client.post(`/products`,
        formData,
        {
            timeout: 0,
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress
        }
    );

    return response.data.data
}

export const update = async (id, product) => {
    const response = await client.put(`/products/${id}`, product);
    return response.data.data
}

export const updateAndPhotosUpload = async (id, product, photos, photo_id, onUploadProgress) => {

    let formData = new FormData();
    for (let key of Object.keys(product)) {
        if (Array.isArray(product[key])) {
            product[key].forEach((value, index) => formData.append(key + "["+index+"]", value))
        } else {
            formData.append(key, product[key])
        }
    }

    if(photo_id !== null) {
        formData.append('photo_id', photo_id)
    }

    if (photos && photos.length > 0) {
        let blobs = photos.filter((photo) => photo.primary).map((photo) => photo.blob)
        if(blobs.length > 0) {
            formData.append('photo', blobs.shift())
        }

        blobs = photos.filter((photo) => !photo.primary).map((photo) => photo.blob)
        blobs.forEach((blob, index) => formData.append('photos['+index+']', blob))
    }

    const response = await client.post(`/products/${id}`,
        formData,
        {
            timeout: 0,
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress
        }
    );

    return response.data.data
}

export const remove = async (id) => {
    const response = await client.delete(`/products/${id}`);
    return response.data.data
}

export const publish = async (id) => {
    const response = await client.post(`/products/${id}/publish`);
    return response.data.data
}

export const pause = async (id) => {
    const response = await client.post(`/products/${id}/pause`);
    return response.data.data
}

export const listPhotos = async (id) => {
    const response = await client.get(`/products/${id}/photos`);
    return response.data.data
}

export const deletePhoto = async (product, id) => {
    const response = await client.delete(`/products/${product}/photos/${id}`);
    return response.data.data
}

