window.jQuery = require('jquery/dist/jquery');
window.$ = window.jQuery;

window.Popper = require('popper.js/dist/popper');
window.bootstrap = require('bootstrap/dist/js/bootstrap');

require('owl.carousel/dist/owl.carousel');
require('@fancyapps/fancybox/dist/jquery.fancybox');

window.WOW = require('wow.js/dist/wow');
