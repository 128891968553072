import React, { useEffect, useRef, useState } from 'react'
import { Redirect, useHistory, Link } from 'react-router-dom'
import {
    IonApp, IonRouterOutlet, IonPage, IonContent, IonFooter,
    IonToolbar
} from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { Subject, from } from 'rxjs';

import { Route, Switch, TabBar, TabButton } from 'components'
import { Context } from 'Context'
import { useAuth } from 'hooks'
import {
    SplashScreen,
    Login,
    Register,
    Home,
    ForgotPassword,
    CreateProduct,
    ListProducts,
    DetailProduct,
    EditProduct,
    BuyProduct,
    BuyPayment,
    BuyDelivery,
    BuyResume,
    Profile,
    EditProfile,
    EditFacebook,
    EditFacebookLink,
    ListSales,
    DetailSales,
    EditBankAccount,
    EditStore,
    Checkout,
    Review,
    Canceled,
    Completed,
    Pending,
    Delivered,
    AccordingVendor,
    Notifications,
    Error404,
    StoreLocation,
    BuyLocation,
    ResetPassword,
} from 'pages'


import { Plugins, StatusBarStyle, } from '@capacitor/core';

const { StatusBar } = Plugins;


const App = () => {
    const facebookLink = useState(true)
    const complete = useState(true)
    const close = useState(false)
    
    const auth = useAuth()
    const onContentScroll = new Subject();

    StatusBar.setStyle({ style: StatusBarStyle.Light });
    StatusBar.setBackgroundColor({ color: '#ffffff' })

    return (
        <Context.Provider value={{ auth, onContentScroll, complete, close, facebookLink }}>
            <IonApp>
                <IonReactRouter>
                    <IonPage>
                        <Switch>
                            <Route guest exact path="/login">
                                <Login />
                            </Route>
                            <Route guest exact path="/register">
                                <Register />
                            </Route>
                            <Route exact path="/forgot/password">
                                <ForgotPassword />
                            </Route>
                            <Route exact path="/password/reset/:token">
                                <ResetPassword />
                            </Route>
                            <Route exact path="/:id-:slug">
                                <BuyProduct />
                            </Route>
                            <Route exact path="/:id-:slug/payment">
                                <BuyPayment />
                            </Route>
                            <Route exact path="/:id-:slug/location">
                                <BuyLocation />
                            </Route>
                            <Route exact path="/:id-:slug/delivery">
                                <BuyDelivery />
                            </Route>
                            <Route exact path="/:id-:slug/resume">
                                <BuyResume />
                            </Route>
                            <Route exact path="/payment-orders/:uuid/checkout">
                                <Checkout />
                            </Route>
                            <Route exact path="/purchase-orders/:uuid/review">
                                <Review />
                            </Route>
                            <Route exact path="/payment-orders/:uuid/status/canceled">
                                <Canceled />
                            </Route>
                            <Route exact path="/payment-orders/:uuid/status/completed">
                                <Completed />
                            </Route>
                            <Route exact path="/payment-orders/:uuid/status/pending">
                                <Pending />
                            </Route>
                            <Route exact path="/payment-orders/:uuid/status/delivered">
                                <Delivered />
                            </Route>
                            <Route exact path="/purchase-orders/:uuid/according-vendor">
                                <AccordingVendor />
                            </Route>
                            <Route auth exact path={["/stores/location/:action", "/stores/location/:id/:action"]}>
                                <StoreLocation />
                            </Route>
                            <Route auth path="/">
                                <Notifications>
                                    <Switch>
                                        {/*<Route auth exact path="/home">
                                        <Home />
                                    </Route>*/}
                                        <Route auth exact path="/profile">
                                            <Profile />
                                        </Route>
                                        <Route auth exact path="/profile/facebook">
                                            <EditFacebook />
                                        </Route>
                                        <Route auth exact path="/profile/facebook/link">
                                            <EditFacebookLink />
                                        </Route>
                                        <Route auth exact path="/profile/edit">
                                            <EditProfile />
                                        </Route>
                                        <Route auth exact path={["/", "/products"]}>
                                            <ListProducts />
                                        </Route>
                                        <Route auth exact path="/products/create">
                                            <CreateProduct />
                                        </Route>
                                        <Route auth exact path="/products/:id">
                                            <DetailProduct />
                                        </Route>
                                        <Route auth exact path="/products/:id/edit">
                                            <EditProduct />
                                        </Route>
                                        <Route auth exact path="/sales">
                                            <ListSales />
                                        </Route>
                                        <Route auth exact path="/sales/:id">
                                            <DetailSales />
                                        </Route>
                                        <Route auth exact path="/bank-accounts/create">
                                            <EditBankAccount />
                                        </Route>
                                        <Route auth exact path="/bank-accounts/:id/edit">
                                            <EditBankAccount />
                                        </Route>
                                        <Route auth exact path="/stores/create">
                                            <EditStore />
                                        </Route>
                                        <Route auth exact path="/stores/:id/edit">
                                            <EditStore />
                                        </Route>
                                      </Switch>
                                    <IonFooter className="ion-no-border" style={{ height: '50px' }}>
                                        <IonToolbar>
                                            <TabBar>
                                                <TabButton to="/products" icon='home' />
                                                <TabButton to="/sales" icon='tag' />
                                                <TabButton to="/profile" icon='user' />
                                            </TabBar>
                                        </IonToolbar>
                                    </IonFooter>
                                </Notifications>
                            </Route>
                        </Switch>
                    </IonPage>
                </IonReactRouter>
            </IonApp>
        </Context.Provider>
    )
}

export default App
