
import React from 'react';
import { NavLink } from "react-router-dom"

export const TabButton = ({ to, icon, active, rest}) => {
    return (
        <NavLink className={`FooterBoxMenu-Link`} to={to} activeClassName="Active" {...rest}>
            <i className={`fa fa-${icon}`}></i>
        </NavLink >
    )
}
