import { client } from '../client'

export const list = async () => {
    const response = await client.get(`/document-types`);
    return response.data.data
}

export const detail = async (id) => {
    const response = await client.get(`/document-types/${id}`);
    return response.data.data
}