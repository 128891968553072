import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from "react-router-dom"
import {
    IonContent,
    IonPage,
    IonTitle,
    IonToolbar,
    IonBackButton,
    IonButton,
    IonButtons,
    IonItem,
    IonLabel,
    IonImg,
    IonCardContent,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonLoading,
} from '@ionic/react'

import { parseJSON, format } from 'date-fns'

import { PurchaseOrderService } from 'services'

import { App, Content, Spinner, Input, Button } from 'components'

import config from 'config'
import { currencyFormat } from 'common'

export const DetailSales = () => {

    const history = useHistory()
    const params = useParams()

    const [errors, setErrors] = useState({})
    const [showAlertError, setShowAlertError] = useState(false);
    const [submitting, setSubmitting] = useState(false)

    const [sale, setSale] = useState(undefined)

    const loadSale = async () => {
        try {
            let sale = await PurchaseOrderService.detail(params.id)
            setSale(sale)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        setSale(history.location.state.sale)
        loadSale()
    }, [])


    const cancel = async () => {
        setSubmitting(true)
        try {
            await PurchaseOrderService.cancel(params.id)
            
            setSale({...sale, status: 'canceled'})

        } catch (error) {
            console.error(error)
        }

        setSubmitting(false)
    }
    
    const complete = async () => {
        setSubmitting(true)
        try {
            await PurchaseOrderService.complete(params.id)

            setSale({...sale, status: 'completed'})
        } catch (error) {
            console.error(error)
        }
        setSubmitting(false)
    }

    if (!sale) {
        return (
            <App title={'Detalle de Venta'} titlebar onButton={() => history.push(`/sales`)}>
                <Content margin>
                    <Spinner />
                </Content >
            </App>
        )
    }

    const { product, quantity } = sale?.products[0];

    return (
        <App title={product?.name || 'Detalle de Venta'} titlebar onButton={() => history.push(`/sales`)}>
            <Content margin>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <article className="App-DatosPago wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.5s">
                            <div className="App-Articulo-Detalle  px-0">
                                <form className="FormularioLogin FormularioBase" action="#">
                                    {
                                        product && <>
                                            <div className="App-Articulo-Detalle-Header">
                                                <div className="BoxTitle">
                                                    <h2 className="Title FontTitle1">{product?.name || 'Detalle del articulo'}</h2>
                                                    <p className="Categorias"><span className="Categoria">{currencyFormat(product.price)} x {quantity} u.</span></p>
                                                </div>
                                                <span className="Numero BgVioleta BorderAll">{currencyFormat(sale.products_amount)}</span>
                                            </div>
                                        </>
                                    }
                                    <div className="App-Articulo-Detalle-Header">
                                        <div className="BoxTitle">
                                            <h2 className="Title FontTitle1">{sale.payment_method.name}</h2>
                                            <p className="Categorias"><span className="Categoria">Forma de pago</span></p>
                                        </div>
                                    </div>
                                    {
                                        sale.delivery_method &&
                                        <div className="App-Articulo-Detalle-Header">
                                            <div className="BoxTitle">
                                                <h2 className="Title FontTitle1">{sale.delivery_method.name}</h2>
                                                <p className="Categorias"><span className="Categoria">Forma de Entrega</span></p>
                                            </div>
                                            {
                                                sale.shipping_amount > 0 &&
                                                <span className="Numero BgVioleta BorderAll">{currencyFormat(sale.shipping_amount)}</span>
                                            }
                                        </div>
                                    }
                                    {
                                        <div className="App-Articulo-Detalle-Header">
                                            <div className="BoxTitle">
                                                <h2 className="Title FontTitle1">Total</h2>
                                            </div>
                                            <span className="Numero BgVioleta BorderAll">{currencyFormat(sale.amount)}</span>

                                        </div>
                                    }
                                    <h2 className="Title FontTitle1 mt-3">Datos del Comprador</h2>
                                    <Input
                                        disabled
                                        type="text"
                                        value={`${sale.buyer.first_name} ${sale.buyer.last_name}`}
                                        placeholder="Nombre" />
                                    <Input
                                        disabled
                                        type="text"
                                        value={sale.buyer.email}
                                        placeholder="Email" />
                                    <Input
                                        disabled
                                        type="text"
                                        value={sale.buyer.phone}
                                        placeholder="Telefono" />
                                    <h2 className="Title FontTitle1 mt-3">Datos de entrega del Comprador</h2>
                                    <Input
                                        disabled
                                        type="text"
                                        value={sale.buyer.country.name}
                                        placeholder="Pais" />
                                    <Input
                                        disabled
                                        type="text"
                                        value={sale.buyer.state.name}
                                        placeholder="Provincia" />
                                    <Input
                                        disabled
                                        type="text"
                                        value={sale.buyer.city.name}
                                        placeholder="Ciudad" />
                                    <Input
                                        disabled
                                        type="text"
                                        value={sale.buyer.district}
                                        placeholder="Barrio" />   
                                    <Input
                                        disabled
                                        type="text"
                                        value={sale.buyer.street_name}
                                        placeholder="Calle" />
                                    {
                                        sale.buyer.between_streets &&
                                        <Input
                                            disabled
                                            type="text"
                                            value={sale.buyer.street_name_alt}
                                            placeholder="Calle 2" />
                                    }
                                    {
                                        sale.buyer.between_streets &&
                                        <Input
                                            disabled
                                            type="text"
                                            value={sale.buyer.between_streets}
                                            placeholder="Entre Calles" />
                                    }
                                    {
                                        sale.buyer.building_name &&
                                        <Input
                                            disabled
                                            type="text"
                                            value={sale.buyer.building_name}
                                            placeholder="Nombre Edificio" />
                                    }
                                    <Input
                                        disabled
                                        type="text"
                                        value={sale.buyer.street_number}
                                        placeholder="Numero" />

                                    {
                                        sale.buyer.zip_cod &&
                                        <Input
                                            disabled
                                            type="text"
                                            value={sale.buyer.zip_code}
                                            placeholder="Codigo Postal" />
                                    }
                                    {
                                        sale.buyer.reference &&
                                        <Input
                                            disabled
                                            type="text"
                                            value={sale.buyer.reference}
                                            placeholder="Referencia" />
                                    }

                                </form>
                            </div>
                            {
                                sale && sale.status != 'completed' && (
                                    <>
                                        {
                                            (sale.delivery_method.id == 1 || sale.delivery_method.id == 2 || sale.payment_method.id == 1) &&
                                            <Button color="primary" disabled={submitting} onClick={complete}>
                                                Finalizar compra
                                            </Button>
                                        }

                                        <Button color="primary" disabled={submitting} onClick={cancel}>
                                            Cancelar compra
                                        </Button>
                                    </>
                                )
                            }
                        </article>
                    </div>
                </div>
            </Content >
            
            <IonLoading
                isOpen={submitting}
                backdropDismiss={false}
                keyboardClose={false}
                message={'Espere por favor...'}
                duration={5000}
            />
        </App>
    )
}