import { client } from '../client'

export const list = async () => {
    const response = await client.get(`/user/bank-accounts`);
    return response.data.data
}

export const detail = async (id) => {
    const response = await client.get(`/user/bank-accounts/${id}`);
    return response.data.data
}

export const create = async (bank) => {
    const response = await client.post(`/user/bank-accounts`, bank);
    return response.data.data
}

export const update = async (id, bank) => {
    const response = await client.put(`/user/bank-accounts/${id}`, bank);
    return response.data.data
}

export const remove = async (id) => {
    const response = await client.delete(`/user/bank-accounts/${id}`);
    return response.data.data
}
