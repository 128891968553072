import React, { useEffect } from 'react'

import { 
    IonContent, 
    IonPage, 
    IonSpinner 
} from '@ionic/react'

import { Title } from 'components'

export const SplashScreen = () => {
    return (
        <IonPage>
            <Title/>
            <IonContent>
                <IonSpinner name="crescent" />
            </IonContent>
        </IonPage>
    )
}