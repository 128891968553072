import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from "react-router-dom"
import {
    IonIcon,
    IonText,
    IonLoading,
    IonAlert
} from '@ionic/react'

import { closeCircleOutline } from 'ionicons/icons'

import {
    ProductService,
    PaymentMethodService,
    PurchaseOrderService,
    CountryService,
    StateService,
    Service
} from 'services'

import { Plugins } from '@capacitor/core';

import { Spinner, SelectOptions, App, Content, Button, Input, Select } from 'components'

const { Geolocation } = Plugins;

export const BuyDelivery = () => {

    const history = useHistory()
    const params = useParams()

    const [errors, setErrors] = useState({})
    const [showAlertError, setShowAlertError] = useState(false)
    const [alertMessage, setAlertMessage] = useState()
    const [submitting, setSubmitting] = useState(false)
    const [loading, setLoading] = useState(true)

    const [countries] = useState([CountryService.DEFAULT_COUNTRY])
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])

    const [product, setProduct] = useState()
    const [payment, setPayment] = useState([])
    const [deliveryMethod, setDeliveryMethod] = useState([])

    const [quantity, setQuantity] = useState(1)
    const [country, setCountry] = useState(1)
    const [state, setState] = useState()
    const [city, setCity] = useState()
    const [streetName, setStreetName] = useState('')
    const [streetNameAlt, setStreetNameAlt] = useState('')
    const [betweenStreets, setBetweenStreets] = useState('')
    const [streetNumber, setStreetNumber] = useState('')
    const [district, setDistrict] = useState('')
    const [buildingName, setBuildingName] = useState('')
    const [zipCode, setZipCode] = useState('')
    const [countryName, setCountryName] = useState('')
    const [stateName, setStateName] = useState('')
    const [cityName, setCityName] = useState('')
    const [reference, setReference] = useState('')

    const [valid, setValid] = useState(false)

    const [lat, setLat] = useState()
    const [long, setLong] = useState()

    const loadPositionGPS = async () => {
        try {
            let position = history.location?.state?.location

            if (!position) {
                position = await Geolocation.getCurrentPosition();
                position = {
                    lat: position.coords.latitude,
                    long: position.coords.longitude
                }
            } else {
                position = {
                    lat: position.lat,
                    long: position.lng
                }
            }

            let data = await Service.location(position)

            setLat(position.lat)
            setLong(position.long)
            if (data) {
                setZipCode(data.zip_code);
                setStreetName(data.street_name)
                setStreetNumber(data.address_number)
                setState(data.state_id)
                setCity(data.city_id)

                let cities = await StateService.cities(data.state_id)
                setCities(cities)
            }

            console.log(data)
        } catch (error) {
            console.log(error);
        }
    }

    const load = async (delivery) => {
        try {
            let states = await CountryService.states(CountryService.DEFAULT_COUNTRY.id)
            setStates(states)

            if (delivery && delivery.state) {
                setState(delivery.state)
                let cities = await StateService.cities(delivery.state)
                setCities(cities)

                if (delivery.city) {
                    setCity(delivery.city)
                }
            }

            await loadPositionGPS()
        } catch (error) {
            console.log(error);
        }
        setLoading(false)
    }

    const loadCities = async () => {
        setCities([])
        let cities = await StateService.cities(state)
        setCities(cities)
        setCity(null)
    }

    useEffect(() => {
        if (!history.location.state) {
            history.replace(`/${params.id}-${params.slug}`)
            return
        }

        console.log(history.location.state);

        let { product, quantity, payment, delivery, location } = history.location.state

        if (!product || !quantity) {
            history.replace(`/${params.id}-${params.slug}`)
            return
        }

        if (!payment) {
            history.replace(`/${params.id}-${params.slug}/payment`, {
                product,
                quantity
            })
            return
        }

        if (!location) {
            history.replace(`/${params.id}-${params.slug}/location`, {
                product,
                quantity,
                payment
            })
            return
        }

        setProduct(product)
        setQuantity(quantity)
        setPayment(payment)
        if (delivery) {
            setDeliveryMethod(delivery.deliveryMethod)
            setCountry(delivery.country)
            setState(delivery.state)
            setCity(delivery.city)
            setStreetName(delivery.streetName)
            setStreetNameAlt(delivery.streetNameAlt)
            setBetweenStreets(delivery.betweenStreets)
            setStreetNumber(delivery.streetNumber)
            setZipCode(delivery.zipCode)
            setDistrict(delivery.district)
            setBuildingName(delivery.buildingName)
            setCountryName(delivery.countryName)
            setStateName(delivery.stateName)
            setCityName(delivery.cityName)
            setReference(delivery.reference)
        }

        if (location) {
            setLat(location.lat);
            setLong(location.lng);
        }

        load(delivery)
    }, [])

    useEffect(() => {
        !loading && state && loadCities()
    }, [state])

    const estimatePurchaseOrder = async () => {
        setSubmitting(true)
        try {
            let data = {
                delivery_method: deliveryMethod[0],
                payment_method: payment.paymentMethod[0],
                first_name: payment.firstName,
                last_name: payment.lastName,
                email: payment.email,
                phone: payment.phone,
                street_name: streetName,
                street_name_alt: streetNameAlt,
                between_streets: betweenStreets,
                street_number: streetNumber,
                zip_code: zipCode,
                city_id: city,
                latitude: lat,
                longitude: long,
                district,
                building_name: buildingName,
                reference,
                products: [
                    {
                        product_id: product.id,
                        quantity
                    }
                ]
            }

            console.log(
                lat,
                long
            );

            let purchaseOrder = await PurchaseOrderService.estimate(data)

            history.push(`/${params.id}-${params.slug}/resume`, {
                product,
                quantity,
                payment,
                location: {
                    lat,
                    long
                },
                delivery: {
                    deliveryMethod,
                    streetName,
                    streetNameAlt,
                    betweenStreets,
                    streetNumber,
                    zipCode,
                    countryName,
                    stateName,
                    cityName,
                    reference
                },
                purchaseOrder,
                createPurchaseOrder: data,
            })
        } catch (error) {
            console.error(error)
            if (error?.response?.data?.errors) {
                setErrors(error?.response?.data?.errors)
            } else {
                setAlertMessage(error?.response?.data?.message)
                setShowAlertError(true)
            }
        }
        setSubmitting(false)
    }

    useEffect(() => {
        setValid(
            streetName && streetName.trim() != '' &&
            district && district.trim() != '' &&
            city && city > 0 &&
            //streetNameAlt && streetNameAlt.trim() != '' &&
            //betweenStreets && betweenStreets.trim() != '' &&
            streetNumber && streetNumber > 0 &&
            //zipCode && zipCode > 0 &&
            //reference && reference.trim() != ''
            deliveryMethod.length == 1
        )
    }, [deliveryMethod, streetName, streetNameAlt, country, state, city, betweenStreets, streetNumber, zipCode, district, buildingName, reference])

    const icons = {
        1: 'motorcycle',
        2: 'handshake-o',
        3: 'motorcycle'
    }

    const labels = {
        1: 'Delivery',
        2: 'Pick Up',
        3: 'Delivery'
    }


    return (
        <App title="Datos de entrega" titlebar onBack={() => history.push(`/${params.id}-${params.slug}/location`, {
            product,
            quantity,
            payment,
            location: {
                lat,
                long
            },
            delivery: {
                deliveryMethod,
                country,
                state,
                city,
                district,
                buildingName,
                streetName,
                streetNameAlt,
                betweenStreets,
                streetNumber,
                zipCode,
                countryName,
                stateName,
                cityName,
                reference
            }
        })}>
            <Content margin>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <article className="App-DatosPago wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.5s">
                            <form className="FormularioLogin FormularioBase" action="#">
                                {
                                    product &&
                                    <SelectOptions
                                        title="Formas de entrega"
                                        icons={icons}
                                        value={deliveryMethod}
                                        options={
                                            (
                                                payment.paymentMethod == 2
                                                ? product.delivery_methods
                                                : product.delivery_methods.filter(delivery => delivery.id != 3)
                                            ).map(delivery => ({
                                                ...delivery,
                                                name: labels[delivery.id]
                                            }))
                                        }
                                        onChange={value => setDeliveryMethod(value)}
                                        errors={errors['delivery_method']} />
                                }
                                <h2 className="Title FontTitle1 mb-3 mt-3">Datos del domicilio</h2>
                                <Select
                                    readOnly
                                    placeholder="Pais *"
                                    value={country}
                                    errors={errors['country_id']} >
                                    {countries.map((country) => <option key={country.id} value={country.id}>{country.name}</option>)}
                                </Select>
                                <Select
                                    empty
                                    placeholder="Departamento *"
                                    value={state}
                                    onChange={e => setState(e.target.value)}
                                    errors={errors['state_id']} >
                                    {states.map((state) => <option key={state.id} value={state.id}>{state.name}</option>)}
                                </Select>
                                {
                                    state && cities && cities.length > 0 &&
                                    <Select
                                        empty
                                        placeholder="Ciudad *"
                                        value={city}
                                        onChange={e => setCity(e.target.value)}
                                        errors={errors['city_id']} >
                                        {cities.map((city) => <option key={city.id} value={city.id}>{city.name}</option>)}
                                    </Select>
                                }
                                <Input
                                    type="text"
                                    value={district}
                                    placeholder="Barrio *"
                                    onChange={e => setDistrict(e.target.value)}
                                    disabled={submitting}
                                    errors={errors['district']}
                                />
                                <Input
                                    type="text"
                                    value={streetName}
                                    placeholder="Calle 1 *"
                                    onChange={e => setStreetName(e.target.value)}
                                    disabled={submitting}
                                    errors={errors['street_name']} />
                                <Input
                                    type="text"
                                    value={streetNameAlt}
                                    placeholder="Calle 2"
                                    onChange={e => setStreetNameAlt(e.target.value)}
                                    disabled={submitting}
                                    errors={errors['street_name_alt']} />
                                {/*
                                <Input
                                    type="text"
                                    value={betweenStreets}
                                    placeholder="Entre Calles"
                                    onChange={e => setBetweenStreets(e.target.value)}
                                    disabled={submitting}
                                    errors={errors['between_streets']} />
                                */}
                                <Input
                                    type="text"
                                    value={buildingName}
                                    placeholder="Nombre Edificio"
                                    onChange={e => setBuildingName(e.target.value)}
                                    disabled={submitting}
                                    errors={errors['building_name']}
                                />
                                <Input
                                    type="number"
                                    value={streetNumber}
                                    placeholder="Número de casa/departamento *"
                                    onChange={e => setStreetNumber(e.target.value)}
                                    disabled={submitting}
                                    errors={errors['street_number']}
                                />
                                {/*<Input
                                    type="text"
                                    value={zipCode}
                                    placeholder="Codigo Postal"
                                    onChange={e => setZipCode(e.target.value)}
                                    disabled={submitting}
                                    errors={errors['zip_code']} />
                                */}
                                <Input
                                    type="text"
                                    value={reference}
                                    placeholder="Referencia"
                                    onChange={e => setReference(e.target.value)}
                                    disabled={submitting}
                                    errors={errors['reference']} />

                                <Button
                                    mb="5"
                                    color="primary"
                                    onClick={() => estimatePurchaseOrder()}
                                    disabled={!valid || submitting}>
                                    Continuar
                                </Button>
                            </form>
                        </article>
                    </div>
                </div>
            </Content>

            <IonAlert
                isOpen={showAlertError}
                onDidDismiss={() => setShowAlertError(false)}
                header={'No se puedo realizar la compra'}
                message={alertMessage || 'Intente nuevamente mas tarde'}
                buttons={['Aceptar']}
            />

            <IonLoading
                isOpen={submitting || loading}
                backdropDismiss={false}
                keyboardClose={false}
                message={'Espere por favor...'}
            />
        </App >
    )
}
