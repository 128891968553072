import React, { useEffect, useRef } from 'react';

export const TextArea = ({ errors,...rest }) => {
    if (rest.value == null)
        rest.value = '';
    return (
        <div className="form-group">
            <textarea className={`Input InputTextarea BorderAll form-control  ${rest.value ? 'TextVioleta' : ''}  ${errors ? 'is-invalid' : ''}`} {...rest}/>
            {
                errors && errors.map((message, index) => (
                    <div className="invalid-feedback" key={index}>
                        {message}
                    </div>
                ))
            }
        </div>
    )
}
