
import React from 'react';

export const TabBar = ({ children }) => {
    return (
        <footer className="App-Footer">
            <div className="container">
                <div className="row">
                    <div className="col12 col-sm-12 col-md-12 col-lg-12">
                        <div className="FooterBoxMenu">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
