import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from "react-router-dom"

import { IonLoading, IonAlert, useIonViewWillEnter } from '@ionic/react'

import { FacebookService } from 'services'

import { App, Content, Input, Spinner, Button, Image, Select } from 'components'

export const EditFacebook = () => {

    const history = useHistory()
    const params = useParams()

    const [errors, setErrors] = useState({})

    const [showAlertError, setShowAlertError] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [loading, setLoading] = useState(true)

    const [user, setUser] = useState()
    const [catalog, setCatalog] = useState({})
    const [valid, setValid] = useState(false)

    const unlinkFacebook = async () => {
        setSubmitting(true)
        try {
            if (await FacebookService.unlink()) {
                history.replace('/profile')
            }
        } catch (error) {
            console.log(error);
        }
        setSubmitting(false)
    }

    const linkCatalog = async () => {
        setSubmitting(true)
        try {
            await FacebookService.linkCatalog(catalog)
            setUser({ ...user, facebook_catalog_link: true })
            history.replace('/profile/facebook', { user: { ...user, facebook_catalog_link: true } })
        } catch (error) {
            console.log(error);
        }
        setSubmitting(false)
    }

    const unlinkCatalog = async () => {
        setSubmitting(true)
        try {
            await FacebookService.unlinkCatalog()
            setUser({ ...user, facebook_catalog_link: false })
            history.replace('/profile/facebook/link', { user: { ...user, facebook_catalog_link: false } })
        } catch (error) {
            console.log(error);
        }
        setSubmitting(false)
    }
    
    const syncFacebook = async () => {
        setSubmitting(true)
        try {
            await FacebookService.upload()
        } catch (error) {
            console.log(error);
        }
        setSubmitting(false)
    }

    const load = async () => {
        setLoading(true)
        try {
            let catalog = await FacebookService.catalog();
            console.log(catalog)
            setCatalog(catalog)
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }

    useEffect(() => {
        const user = history.location?.state?.user;
        if (!user) {
            history.replace('/profile')
        }

        setUser(user)

        load()
    }, [])

    useEffect(() => {
        setValid(catalog && catalog != "")
    }, [catalog])


    return (
        <App title="Facebook" titlebar onBack={() => history.goBack()}>
            <Content margin>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <article className="wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.5s">
                            <div className="App-MiCuenta-DatosFormularios mt-4">
                                <div className="App-MiCuenta-DatosFormularios-Box mb-4 wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.5s">
                                    <h2 className="Title FontTitle1 mb-3">Catalogo Viculado</h2>
                                    <div className="FormularioLogin FormularioBase" action="#">
                                        <Input  type="text" value={catalog.name} disabled/>
                                        <Button onClick={syncFacebook} color="primary">Sincronizar ahora</Button>
                                        <Button onClick={unlinkCatalog}  mb={5} color="danger">Desvincular Catalogo</Button>
                                        <Button onClick={unlinkFacebook} color="danger">Desvincular Facebook</Button>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </Content>
            <IonAlert
                isOpen={showAlertError}
                onDidDismiss={() => setShowAlertError(false)}
                header={'No se puedo actualizar el perfil'}
                message={'Intente nuevamente mas tarde'}
                buttons={['Aceptar']}
            />

            <IonLoading
                isOpen={loading || submitting}
                backdropDismiss={false}
                keyboardClose={false}
                message={'Espere por favor...'}
            />

        </App>
    )
}

