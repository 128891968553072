import React from 'react';

export const ButtonBoxGradent = ({ children, onClick, active = true, icon = 'plus-circle', ...rest }) => {
    return (
        <div className={`BoxFormaPago BoxAgregarFotos BorderAll BgAzul ${ active ? 'Active' : ''}`} onClick={ (event) => onClick && onClick(event)}>
            <i className={`Icon IconTarjeta fa fa-${icon}`}></i>
            <p className="Title">{children}</p>
        </div>
    )
}
