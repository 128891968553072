import React, { useEffect, useRef } from 'react';

import NumberFormat from 'react-number-format';

import config from 'config'

export const InputCurrency = ({ errors, ...rest }) => {
    if (rest.value == null)
        rest.value = '';

    return (
        <div className="form-group">
            <NumberFormat 
                className={`Input BorderAll form-control ${rest.value ? 'TextVioleta' : ''} ${errors ? 'is-invalid' : ''}`}
                prefix={'Gs. '}
                thousandSeparator={'.'} 
                decimalSeparator={','}
                decimalScale={config.decimal}
                fixedDecimalScale={true}
                allowNegative={false}
                {...rest} 
                />
            {
                errors && errors.map((message, index) => (
                    <div className="invalid-feedback" key={index}>
                        {message}
                    </div>
                ))
            }
        </div>
    )
}
