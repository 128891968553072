import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

//console.log(jQuery);
/* Basic CSS for apps built with Ionic */
//import '@ionic/react/css/normalize.css';
//import '@ionic/react/css/structure.css';
//import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
//import '@ionic/react/css/padding.css';
//import '@ionic/react/css/float-elements.css';
//import '@ionic/react/css/text-alignment.css';
//import '@ionic/react/css/text-transformation.css';
//import '@ionic/react/css/flex-utils.css';
//import '@ionic/react/css/display.css';

import 'animate.css/animate.css';
import 'wow.js/css/libs/animate.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fancyapps/fancybox/dist/jquery.fancybox.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'assets/css/font-awesome.css';
import 'assets/css/owl.carousel.css';
import 'assets/css/app.css';
import 'assets/css/responsive.css';

import 'main';

/* Theme variables */
//import './theme/variables.css';

ReactDOM.render(<App />, document.getElementById('root'));

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
//serviceWorker.register();
