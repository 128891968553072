import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from "react-router-dom"
import {
    IonLoading,
    IonAlert
} from '@ionic/react'

import { currencyFormat } from 'common'

import { PurchaseOrderService } from 'services'

import { App, Content, Button, Input, Spinner } from 'components'

import config from 'config'

export const BuyResume = () => {

    const history = useHistory()
    const params = useParams()
    let refDetail = useRef()

    const [errors, setErrors] = useState({})
    const [showAlertError, setShowAlertError] = useState(false)
    const [alertMessage, setAlertMessage] = useState()
    const [submitting, setSubmitting] = useState(false)

    const [product, setProduct] = useState()
    const [quantity, setQuantity] = useState()
    const [payment, setPayment] = useState()
    const [delivery, setDelivery] = useState()
    const [purchaseOrder, setPurchaseOrder] = useState()

    const [showDetail, setShowDetail] = useState(false)
    const [showLimitDetail, setShowLimitDetail] = useState(false)

    useEffect(() => {
        if (!history.location.state) {
            history.replace(`/${params.id}-${params.slug}`)
            return
        }

        let { product, quantity, payment, delivery, purchaseOrder } = history.location.state

        if (!product || !quantity) {
            history.replace(`/${params.id}-${params.slug}`)
            return
        }

        if (!payment) {
            history.replace(`/${params.id}-${params.slug}/payment`, {
                product,
                quantity
            })
            return
        }

        if (!delivery || !purchaseOrder) {
            history.replace(`/${params.id}-${params.slug}/delivery`, {
                product,
                quantity,
                payment
            })
            return
        }

        setProduct(product)
        setQuantity(quantity)
        setPayment(payment)
        setDelivery(delivery)
        setPurchaseOrder(purchaseOrder)
    }, [])

    const buy = async () => {
        setSubmitting(true)
        try {
            let { createPurchaseOrder } = history.location.state;
            let purchaseOrder = await PurchaseOrderService.create(createPurchaseOrder);

            if (purchaseOrder?.payment_orders[0]?.checkout_url) {
                window.location.replace(purchaseOrder?.payment_orders[0]?.checkout_url)
            } else {
                history.replace(`/purchase-orders/${purchaseOrder.uuid}/according-vendor`)
            }
        } catch (error) {
            console.error(error)
            if (error?.response?.data?.errors) {
                setErrors(error?.response?.data?.errors)
            } else {
                setAlertMessage(error?.response?.data?.message)
                setShowAlertError(true)
            }
        }
        setSubmitting(false)
    }

    const toggleDetail = (event) => {
        event.preventDefault()
        setShowDetail(!showDetail);
    }

    const handleShowLimitDetail = (ref) => {
        setTimeout(() => {
            if (ref && ref.clientHeight >= 150) {
                setShowLimitDetail(true)
            }
        }, 500)
    }

    if (!purchaseOrder) {
        return (
            <App title="Resumen de Compra" titlebar onBack={() => history.push(`/${params.id}-${params.slug}/delivery`, {
                product,
                quantity,
                delivery,
                payment
            })}>
                <Content>
                    <Spinner />
                </Content>
            </App>
        )
    }

    const { quantity: purchaseQuantity, amount, unit_price } = purchaseOrder.products[0]
    const { buyer, payment_method, delivery_method } = purchaseOrder;

    return (
        <App title="Resumen de Compra" titlebar onBack={() => history.push(`/${params.id}-${params.slug}/delivery`, {
            product,
            quantity,
            delivery,
            payment
        })}>
            <Content margin>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <article className="App-DatosPago wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.5s">
                            <div className="App-Articulo-Detalle  px-0">
                                <div className="App-Brands mb-2">
                                    {
                                        (product.user?.shopping && product.user?.shopping.logo)
                                            ? <img className="ImgLogo" src={`${config.url}/storage/${product.user.shopping.logo}`} alt={product.user.shopping.name} />
                                            : <img className="ImgLogo" src="assets/img/storeados-isologo-rose.png" alt="Storeados" />
                                    }
                                </div>
                                {
                                    product && <>
                                        <div className="App-Articulo-Detalle-Header">
                                            <div className="BoxTitle">
                                                <h2 className="Title FontTitle1">{product?.name || 'Detalle del artículo'}</h2>
                                                <p className="Categorias"><span className="Categoria">{product.category?.category?.name}</span><span className="Categoria">{product.category.name}</span></p>
                                            </div>

                                        </div>
                                        <div className="App-Articulo-Detalle-Descripcion">
                                            <h2 className="Title FontTitle1">Descripción</h2>
                                            <div className={`BoxText ${showDetail ? 'ShowText' : ''}`} ref={(ref) => handleShowLimitDetail(ref)}>
                                                <p className="Text">{product.detail}</p>
                                            </div>
                                            {
                                                showLimitDetail &&
                                                <div className="w-100 text-center">
                                                    <a className="LinkVerMas BorderAll" onClick={toggleDetail} href="#">{!showDetail ? 'Ver más' : 'Ocultar'}
                                                        <i className={`fa fa-angle-${!showDetail ? 'down' : 'up'}`}></i>
                                                    </a>
                                                </div>
                                            }
                                        </div>
                                    </>
                                }
                                <h2 className="Title FontTitle1">Datos personales</h2>
                                <form className="FormularioLogin FormularioBase" action="#">
                                    <Input
                                        disabled
                                        type="text"
                                        value={buyer.first_name}
                                        placeholder="Nombre" />
                                    <Input
                                        disabled
                                        type="text"
                                        value={buyer.last_name}
                                        placeholder="Apellido" />
                                    <Input
                                        disabled
                                        type="text"
                                        value={buyer.email}
                                        placeholder="Email" />
                                    <Input
                                        disabled
                                        type="text"
                                        value={buyer.phone}
                                        placeholder="Telefono" />

                                </form>
                                <h2 className="Title FontTitle1 mt-4">Datos del domicilio</h2>
                                <form className="FormularioLogin FormularioBase" action="#">
                                    <Input
                                        disabled
                                        type="text"
                                        value={buyer.country.name}
                                        placeholder="Pais" />
                                    <Input
                                        disabled
                                        type="text"
                                        value={buyer.state.name}
                                        placeholder="Provincia" />
                                    <Input
                                        disabled
                                        type="text"
                                        value={buyer.city.name}
                                        placeholder="Ciudad" />
                                    <Input
                                        disabled
                                        type="text"
                                        value={buyer.district}
                                        placeholder="Barrio" />
                                    <Input
                                        disabled
                                        type="text"
                                        value={buyer.street_name}
                                        placeholder="Calle 1" />
                                    {
                                        buyer.street_name_alt &&
                                        <Input
                                            disabled
                                            type="text"
                                            value={buyer.street_name_alt}
                                            placeholder="Calle 2" />
                                    }
                                    {/*
                                        buyer.between_streets &&
                                        <Input
                                            disabled
                                            type="text"
                                            value={buyer.between_streets}
                                            placeholder="Entre Calles" />
                                    */}
                                    {
                                        buyer.building_name &&
                                        <Input
                                            disabled
                                            type="text"
                                            value={buyer.building_name}
                                            placeholder="Nombre Edificio" />
                                    }
                                    <Input
                                        disabled
                                        type="text"
                                        value={buyer.street_number}
                                        placeholder="Número de casa/departamento" />
                                    {/*
                                        <Input
                                            disabled
                                            type="text"
                                            value={buyer.zip_code}
                                            placeholder="Codigo Postal" />
                                    */}
                                    {buyer.reference &&
                                        <Input
                                            disabled
                                            type="text"
                                            value={buyer.reference}
                                            placeholder="Referencia" />
                                    }
                                </form>
                                <div className="App-Articulo-Detalle-Header">
                                    <div className="BoxTitle">
                                        <h2 className="Title FontTitle1">{payment_method.name}</h2>
                                        <p className="Categorias"><span className="Categoria">Forma de pago</span></p>
                                    </div>
                                </div>
                                {
                                    delivery_method &&
                                    <div className="App-Articulo-Detalle-Header">
                                        <div className="BoxTitle">
                                            <h2 className="Title FontTitle1">{delivery_method.name}</h2>
                                            <p className="Categorias"><span className="Categoria">Forma de Entrega</span></p>
                                        </div>
                                        {
                                            purchaseOrder.shipping_amount &&
                                            <span className="Numero BgVioleta BorderAll">{currencyFormat(purchaseOrder.shipping_amount)}</span>
                                        }
                                    </div>
                                }
                                {
                                    product &&
                                    <div className="App-Articulo-Detalle-Header">
                                        <div className="BoxTitle">
                                            <h2 className="Title FontTitle1">Productos</h2>
                                        </div>
                                        <span className="Numero BgVioleta BorderAll">{currencyFormat(product.price)} x {purchaseQuantity}</span>
                                    </div>
                                }
                                <Button
                                    color="primary"
                                    onClick={() => buy()}
                                    disabled={submitting}
                                    mb="5">
                                    Comprar {currencyFormat(purchaseOrder.amount)}
                                </Button>
                            </div>
                        </article>
                    </div>
                </div>
            </Content>

            <IonAlert
                isOpen={showAlertError}
                onDidDismiss={() => setShowAlertError(false)}
                header={'No se puedo realizar la compra'}
                message={alertMessage || 'Intente nuevamente mas tarde'}
                buttons={['Aceptar']}
            />

            <IonLoading
                isOpen={submitting}
                backdropDismiss={false}
                keyboardClose={false}
                message={'Espere por favor...'}
            />
        </App >
    )
}