import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from "react-router-dom"
import {
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonRefresher,
    IonRefresherContent
} from '@ionic/react'

import { List, Map, fromJS } from 'immutable'

import config from 'config'

import { ProductService } from 'services'

import { currencyFormat } from 'common'

import { useContext } from 'hooks'

import { App, Content, ListTitle, InputSearch, Spinner, Image, Button } from 'components'

import { FacebookService } from 'services'

import productoSinImagen from 'assets/img/producto-sin-imagen.png'

export const ListProducts = () => {

    const history = useHistory()
    const { facebookLink: [facebookLink, setFacebookLink] } = useContext()

    const refresh = useRef()
    const infiniteScroll = useRef()
    const timer = useRef()

    const [products, setProducts] = useState(new List())
    const [curretPage, setCurrentPage] = useState(0)
    const [search, setSearch] = useState()
    const [submitting, setSubmitting] = useState(false)
    const [more, setMore] = useState(false)

    const loadProducts = async (page, isRefresh) => {
        try {
            let { data, meta } = await ProductService.listByUser({
                page,
                query: search
            })

            setCurrentPage(page)
            setMore(meta.to < meta.total)

            data = fromJS(data);

            setProducts(
                page == 1 ? data : products.concat(data)
            )
        } catch (error) {
            console.error(error)
        }

        infiniteScroll.current && infiniteScroll.current.complete()

        isRefresh && refresh.current && refresh.current.complete()

        setSubmitting(false)
    }
    const changeState = async (product, index) => {
        try {
            let { id, status } = product

            if (status === 'paused') {
                await ProductService.publish(id)
                product.status = 'published'
            } else {
                await ProductService.pause(id)
                product.status = 'paused'
            }

            setProducts(products.set(index, new Map(product)))
        } catch (error) {
            console.error(error)
        }
    }
    const moreProducts = async () => {
        let nextPage = curretPage + 1;
        loadProducts(nextPage);
    }

    useEffect(() => {
        setSubmitting(true)
        loadProducts(1);
    }, [])

    useEffect(() => {
        timer.current && clearTimeout(timer.current)
        timer.current = setTimeout(() => {
            setSubmitting(true)
            loadProducts(1);
        }, 500)

        return () => {
            timer.current && clearTimeout(timer.current)
        }
    }, [search])

    const linkFacebook = async () => {
        setSubmitting(true)
        try {
            if (await FacebookService.link()) {
                setFacebookLink(true)
            }
        } catch (error) {
            console.log(error);
        }
        setSubmitting(false)
    }

    const limitString = (string, max, endpad = '...') => {
        string = string || ''

        if (string.length > max) {
            string = `${string.substring(0, max)}${endpad}`
        }

        return string;
    }

    const renderProduct = (product, index) => {

        let status = 'No publicado';
        switch (product.status) {
            case 'published':
                status = 'Publicado';
                break;
            case 'paused':
                status = 'Publicacion pausada';
                break;
        }

        return (
            <a key={product.id}
                onClick={e => { e.preventDefault(); history.push(`products/${product.id}`, { product }) }}
                href="articulo.html"
                className="BoxProducto wow fadeInUp"
                data-wow-duration="0.6s"
                data-wow-delay="0.5s"
            >
                <Image path={product.photo} invalid={productoSinImagen} />
                <div className="BoxDetalle">
                    <div className="PrecioPanel">
                        <span className="Precio BgVioleta BorderAll">{currencyFormat(product.price)}</span>
                        { 
                            product.stock > 0 
                             ? <span className="Precio BorderAll Stock">Stock: {product.stock}</span>
                             : <span className="Precio BorderAll StockOut">Sin Stock</span>
                        }
                    </div>
                    <h3 className="BoxDetalle-Title FontTitle2">{product.name}</h3>
                    <h3 className="BoxDetalle-Title FontTitle2">{status}</h3>
                    <p className="BoxDetalle-Text">{limitString(product.detail, 100)}</p>
                </div>
            </a>
        )
    }

    return (
        <App title="Mis Artículos" titlebar backButton={false} icon="plus" onButton={() => history.push('/products/create')}>
            <Content margin>
                <IonRefresher slot="fixed" onIonRefresh={() => loadProducts(1, true)} ref={refresh}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                <InputSearch placeholder="Buscar en mis artículos"
                    defaultValue={search}
                    onChange={(event) => setSearch(event.target.value)} />
                { !facebookLink && <Button onClick={linkFacebook} color="facebook">Vincular Facebook</Button> }
                <ListTitle>Productos</ListTitle>
                <div className="App-Articulos-List-Productos">
                    {submitting ? <Spinner /> : products.map((product, index) => renderProduct(product.toJS(), index))}
                </div>
                <IonInfiniteScroll
                    threshold="100px"
                    onIonInfinite={() => moreProducts()}
                    disabled={!more}
                    ref={infiniteScroll}
                >
                    <IonInfiniteScrollContent
                        loadingSpinner="crescent" />
                </IonInfiniteScroll>
            </Content>
        </App>
    )
}