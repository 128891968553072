import React, { useEffect } from 'react';

import {
    Switch as ReactSwitch,
    useHistory 
} from "react-router-dom";

import { useContext } from '../hooks'

export const Switch = ({ children, ...rest }) => {
    
    const history = useHistory()
    const { auth } = useContext()

    const load = async () => {
        await auth.load()
       /* if (auth.isAuthenticated() && (!auth.isAccessTokenExpired() || await auth.refreshToken())) {
            history.replace('/home')
            return
        }

        history.replace('/login')*/
    }

    useEffect(() => {
        load()
    }, [])

    return <ReactSwitch {...rest}>{children}</ReactSwitch>
}
