import React, { useEffect, useState } from 'react';

import { UserService } from 'services'

import { useContext } from 'hooks'
import { useHistory } from 'react-router';

export const Content = ({ children, margin, className, messages=true }) => {

    const history = useHistory()
    const { 
        facebookLink: [facebookLink, setFacebookLink], 
        complete: [complete, setComplete], 
        close: [close, setClose],
        auth
    } = useContext()

    useEffect(() => {
        auth.isAuthenticated() && UserService.user().then(
            user => {
                setComplete(user.profile_completed)
                setFacebookLink(user.facebook_link)
            }
        )
    }, []);

    return (
        <section className={`App-Content BgWhite ${className} ${margin ? 'App-Titlebar' : ''}`}>
            <div className="container">
                {
                    messages && auth.isAuthenticated() && !complete && !close &&
                    <div className="alert alert-warning alert-dismissible fade show Alerta" role="alert" onClick={ () => history.push('/profile') }>
                        <strong>Importante!</strong> Completa tu perfil de vendedor para publicar tus productos
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={ (event) => {
                            event.stopPropagation()
                            setClose(true) 
                        }}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                }
                {children}
            </div>
        </section>
    )
}
