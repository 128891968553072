import React from 'react';

export const Select = ({ children, errors, empty, placeholder = '', ...rest }) => {
    if (!rest.value)
        rest.value = '';

    return (
        <div className="form-group">
            <select className={`Select BorderAll w-100 ${rest.value ? 'TextVioleta' : ''}  ${errors ? 'is-invalid' : ''}`} {...rest}>
                {empty && <option value="">{placeholder}</option>}
                {children}
            </select>
            {
                errors && errors.map((message, index) => (
                    <div className="invalid-feedback" key={index}>
                        {message}
                    </div>
                ))
            }
        </div>
    )
}