import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from "react-router-dom"

import {
    PaymentMethodService,
} from 'services'

import { SelectOptions, Spinner, App, Content, Button, Input } from 'components'

export const BuyPayment = () => {

    const history = useHistory()
    const params = useParams()

    const [errors, setErrors] = useState({})
    const [submitting, setSubmitting] = useState(false)
    const [loading, setLoading] = useState(true)

    const [paymentMethods, setPaymentMethods] = useState([])

    const [product, setProduct] = useState()
    const [paymentMethod, setPaymentMethod] = useState([])
    const [quantity, setQuantity] = useState(1)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('') // 0961123456

    const [delivery, setDelivery] = useState(0)
    const [valid, setValid] = useState(false)

    const load = async (product, quantity) => {
        setLoading(true)
        try {
            let paymentMethods = await PaymentMethodService.all()

            let amount = product.price * quantity
            paymentMethods = paymentMethods.map(payment => ({
                ...payment,
                disabled: !((!payment.min_allowed_amount || payment.min_allowed_amount < amount) &&
                           (!payment.max_allowed_amount || payment.max_allowed_amount >= amount))
            }) )

            setPaymentMethods(paymentMethods)
        } catch (error) {
            console.error(error)
        }
        setLoading(false)
    }

    const next = () => {
        history.push(`/${params.id}-${params.slug}/location`, {
            product,
            quantity,
            delivery,
            payment: {
                paymentMethod,
                firstName,
                lastName,
                email,
                phone
            }
        })
    }

    useEffect(() => {
        if (!history.location.state) {
            history.replace(`/${params.id}-${params.slug}`)
            return
        }

        let { product, quantity, payment, delivery } = history.location.state

        if (!product || !quantity) {
            history.replace(`/${params.id}-${params.slug}`)
            return
        }

        setProduct(product)
        setQuantity(quantity)
        setDelivery(delivery)
        if (payment) {
            setPaymentMethod(payment.paymentMethod)
            setFirstName(payment.firstName)
            setLastName(payment.lastName)
            setEmail(payment.email)
            setPhone(payment.phone)
        }
        load(product, quantity)
    }, [])

    useEffect(() => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const emailValid = re.test(String(email).toLowerCase());

        if(email && email.trim() !== '' && !emailValid) {
            setErrors({...errors, email: [ "El correo electronico no es valido" ]})
        } else {
            setErrors({...errors, email: null})
        }

        // if(phone && (phone.trim().length < 8 || phone.trim().length > 11)) {
        //     setErrors({...errors, phone: [ "El numero de telefono no es valido" ]})
        // } else {
        //     setErrors({...errors, phone: null})
        // }
                
        setValid(
            firstName && firstName.trim() !== '' &&
            lastName && lastName.trim() !== '' &&
            email && email.trim() !== '' &&
            phone && phone.trim() !== '' &&
            paymentMethod.length == 1 &&
            emailValid
        )
    }, [paymentMethod, firstName, lastName, email, phone])

    const icons = {
        1: 'handshake-o',
        2: 'credit-card-alt',
        3: 'money',
    }

    return (
        <App title="Datos de pago" titlebar onBack={() => history.push(`/${params.id}-${params.slug}`)}>
            <Content margin>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <article className="App-DatosPago wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.5s">
                            <form className="FormularioLogin FormularioBase" action="#">
                                {
                                    loading ? <Spinner /> :
                                        <SelectOptions
                                            title="Formas de pago"
                                            icons={icons}
                                            value={paymentMethod}
                                            options={paymentMethods}
                                            onChange={value => setPaymentMethod(value)}
                                            errors={errors['payment_method']} />
                                }
                                <h2 className="Title FontTitle1 mb-3 mt-3">Datos de personales</h2>
                                <Input
                                    type="text"
                                    value={firstName}
                                    placeholder="Nombre *"
                                    onChange={e => setFirstName(e.target.value)}
                                    disabled={submitting}
                                    errors={errors['first_name']} />
                                <Input
                                    type="text"
                                    value={lastName}
                                    placeholder="Apellido *"
                                    onChange={e => setLastName(e.target.value)}
                                    disabled={submitting}
                                    errors={errors['last_name']} />
                                <Input
                                    type="email"
                                    value={email}
                                    placeholder="Email *"
                                    onChange={e => setEmail(e.target.value)}
                                    disabled={submitting}
                                    errors={errors['email']} />
                                <Input
                                    type="number"
                                    value={phone}
                                    placeholder="Telefono *"
                                    // onChange={e => setPhone(e.target.value.substring(0,9))}
                                    onChange={e => setPhone(e.target.value)}
                                    disabled={submitting}
                                    errors={errors['phone']} />

                                <Button
                                    color="primary"
                                    disabled={!valid} mb="5"
                                    onClick={() => next()}>
                                    Continuar
                                </Button>
                            </form>
                        </article>
                    </div>
                </div>
            </Content>
        </App >

    )
}