import React from 'react';

import config from 'config'

export const Image = ({ size = 'medium', path, invalid }) => {

    let url = `${config.url}/images/${size}/${path}`

    if(path && path.includes("http"))
        url = path;

    return (
        <img className="BoxImg BorderAll" src={path ? url : invalid} />
    )
}
