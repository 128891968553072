import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from "react-router-dom"
import {
    IonLoading,
    IonAlert,
    IonGrid,
    IonRow,
    IonCol,
    IonActionSheet,
    IonToast
} from '@ionic/react'

import { isPlatform } from '@ionic/react';

import { useFilesystem, base64FromPath } from '@ionic/react-hooks/filesystem';
import { useCamera } from '@ionic/react-hooks/camera';

import { camera, share } from 'ionicons/icons';

import {
    ProductService,
    CategoryService,
    DeliveryMethodService
} from 'services'

import {
    App,
    Content,
    InputFile,
    Input,
    TextArea,
    ButtonBoxGradent,
    Button,
    ImageAndDelete,
    Select,
    SelectOptions,
    InputCurrency,
    InputNumber,
    SelectOptionsGroups
} from 'components'

import {
    Plugins,
    Capacitor,
    CameraResultType,
    CameraSource,
    CameraPhoto,
    FilesystemDirectory
} from "@capacitor/core";

import config from 'config'

const { Share, Browser } = Plugins;

let groups = {
    1: 1,
    3: 1
}

export const CreateProduct = () => {

    const history = useHistory()

    const inputFile = useRef()

    const { getPhoto } = useCamera()

    const [showActionSheet, setShowActionSheet] = useState(false);
    const [showIncomplet, setShowIncomplet] = useState(false);

    const [categories, setCategories] = useState([])
    const [subCategories, setSubCategories] = useState([])
    const [deliveryMethods, setDeliveryMethods] = useState([])

    const [name, setName] = useState('')
    const [detail, setDetail] = useState('')
    const [price, setPrice] = useState()
    const [stock, setStock] = useState()
    const [category, setCategory] = useState('')
    const [subCategory, setSubCategory] = useState('')
    const [deliveryMethod, setDeliveryMethod] = useState([])
    const [photos, setPhotos] = useState([])

    const [errors, setErrors] = useState({})
    const [showAlertError, setShowAlertError] = useState(false);
    const [alertMessage, setAlertMessage] = useState();
    const [submitting, setSubmitting] = useState(false)
    const [loading, setLoading] = useState(true)
    const [progress, setProgress] = useState(false)
    const [cameraAvailable, setCameraAvailable] = useState(false)
    const [valid, setValid] = useState(false)

    const load = async () => {
        try {
            let [
                categories,
                deliveryMethods,
            ] = await Promise.all([
                CategoryService.all(),
                DeliveryMethodService.all()
            ])

            setCategories(categories)
            setDeliveryMethods(deliveryMethods)
        } catch (error) {
            // Error
        }

        setLoading(false)
    }

    const loadSubCategories = async () => {
        try {
            if (category) {
                let categories = await CategoryService.getChilds(category);
                setSubCategory(null)
                setSubCategories(categories)
            }
        } catch (error) {
            // Error
        }
    }

    useEffect(() => {
        setCameraAvailable(Capacitor.isPluginAvailable('camera'))
        load()
    }, [])

    useEffect(() => { loadSubCategories() }, [category])


    useEffect(() => {
        console.log(price, stock)
        setValid(
            name.trim() !== '' &&
            category > 0 &&
            subCategory > 0 &&
            detail.trim() !== '' &&
            price > 0 &&
            stock > 0 &&
            deliveryMethod.length > 0 &&
            photos.length > 0
        )
    }, [name, category, subCategory, detail, price, stock, deliveryMethod, photos])

    const onUploading = (e) => {
        console.log("onUploading", JSON.stringify(e));
        setProgress(e.loaded / e.total)
    }

    const publish = async (event, share) => {
        if (!valid) {
            event.preventDefault()
            setShowIncomplet(true)
            return;
        }

        setSubmitting(true)

        try {
            let product = await ProductService.createAndPhotosUpload({
                name,
                category_id: subCategory,
                delivery_methods: deliveryMethod,
                detail,
                price,
                stock
            }, photos, onUploading)

            setSubmitting(false)

            if(share) {
                try {
                    await Share.share({
                        title: product.name,
                        text: product.detail,
                        url: product.url,
                        dialogTitle: 'Compartir Producto'
                    });
                } catch (error) {
                    console.log(error)
                }
            }

            history.replace('/products');
            return

        } catch (error) {
            console.log("error", error)
            if (error?.response?.data?.errors) {
                setErrors(error?.response?.data?.errors)
            } else {
                setAlertMessage(error?.response?.data?.message)
                setShowAlertError(true)
            }
        }
        setProgress(false)
        setSubmitting(false)
    }

    const takePicture = async () => {
        const photo = await getPhoto({
            quality: 100,
            resultType: CameraResultType.Uri,
            source: CameraSource.Camera,
        })

        let response = await fetch(photo.webPath);

        setPhotos([...photos, {
            url: photo.webPath,
            blob: await response.blob(),
            primary: (!photos || !photos.length)
        }].splice(0, config.max_photos))

        setLoading(true)
    }

    const addPhotos = (files) => {
        files = [...files].map(file => ({
            url: URL.createObjectURL(file),
            blob: file,
            primary: false
        }))

        if ((!photos || !photos.length) && files[0]) {
            files[0].primary = true
        }

        setPhotos([...photos, ...files].splice(0, config.max_photos))
    }

    const deletePhoto = (index) => {
        let primary = photos[index].primary

        let files = photos.filter((_, i) => index != i);

        if (primary && files && files.length && files[0]) {
            files[0].primary = true
        }

        setPhotos(files)
    }

    const setPrimaryPhoto = (index) => {
        setPhotos(photos.map((photo, i) => ({ ...photo, primary: i == index })))
    }

    const icons = {
        1: 'motorcycle',
        2: 'handshake-o',
        3: 'motorcycle'
    }

    return (
        <App title="Nuevo Artículo" titlebar /*icon={true ? 'camera' : ''} onButton={e => takePicture()} */>
            <Content margin className="App-MiCuenta-DatosFormularios">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <article className="App-DatosPago wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.5s">
                            <IonGrid fixed>
                                <IonRow>
                                    {
                                        photos.map((photo, index) =>
                                            <IonCol sizeMd="4" sizeSm="6" sizeXl="4" sizeXs="12" key={index}>
                                                <ImageAndDelete
                                                    src={photo.url}
                                                    onError={() => setLoading(false)}
                                                    onDidLoad={() => setLoading(false)}
                                                    onDelete={() => deletePhoto(index)}
                                                    onClick={() => setPrimaryPhoto(index)}
                                                    check={photo.primary} />
                                            </IonCol>
                                        )
                                    }

                                </IonRow>
                            </IonGrid>

                            <InputFile onFiles={addPhotos} ref={inputFile} />
                            {
                                photos.length < config.max_photos &&
                                <div className="App-DatosPago-Forma App-DatosPago-Fotos">
                                    <ButtonBoxGradent onClick={() => setShowActionSheet(true)}>Agregar Fotos</ButtonBoxGradent>
                                    <p className="Text"><strong>Fotos {photos.length}/{config.max_photos}:</strong> Cargue las fotos del producto y elige la foto principal del anuncio.</p>
                                </div>
                            }

                            <div className="App-MiCuenta-DatosFormularios mt-4">
                                <div className="App-MiCuenta-DatosFormularios-Box mb-4 wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.5s">
                                    <form className="FormularioLogin FormularioBase" action="#">
                                        <Input
                                            type="text"
                                            value={name}
                                            placeholder="Titulo *"
                                            onChange={e => setName(e.target.value)}
                                            disabled={submitting}
                                            errors={errors['name']} />

                                        <Select
                                            empty
                                            placeholder="Categoria *"
                                            value={category}
                                            onChange={e => setCategory(e.target.value)}
                                            disabled={!categories.length}>
                                            {categories.map(category => <option key={category.id} value={category.id}>{category.name}</option>)}
                                        </Select>

                                        {
                                            subCategories.length > 0 && <Select
                                                empty
                                                placeholder="Sub Categoria *"
                                                errors={errors['category_id']}
                                                value={subCategory}
                                                onChange={e => setSubCategory(e.target.value)}
                                                disabled={!subCategories.length}>
                                                {subCategories.map(category => <option key={category.id} value={category.id}>{category.name}</option>)}
                                            </Select>
                                        }

                                        <TextArea
                                            type="text"
                                            value={detail}
                                            placeholder="Descripción adicional *"
                                            onChange={e => setDetail(e.target.value)}
                                            disabled={submitting}
                                            errors={errors['detail']}
                                            rows={10}
                                            cols={30} />

                                        <InputCurrency
                                            value={price}
                                            placeholder="Precio *"
                                            onValueChange ={data => setPrice(data.value)}
                                            disabled={submitting}
                                            errors={errors['price']} />

                                        <InputNumber
                                            value={stock}
                                            placeholder="Stock *"
                                            onValueChange={data => setStock(data.value)}
                                            disabled={submitting}
                                            errors={errors['stock']} />

                                        <SelectOptionsGroups
                                            multiple
                                            title="Formas de Entrega"
                                            icons={icons}
                                            value={deliveryMethod}
                                            options={deliveryMethods.map(delivery => ({
                                                ...delivery,
                                                group: groups[delivery.id]
                                            }))}
                                            onChange={value => setDeliveryMethod(value)}
                                            errors={errors['delivery_methods']} />

                                        <Button mt={3} onClick={(event) => publish(event)} color={valid && 'primary'} disabled={submitting}>Guardar</Button>
                                        <Button mt={3} onClick={(event) => publish(event, true)} color={valid && 'primary'} disabled={submitting}>Guardar y Compartir</Button>
                                    </form>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </Content>

            <IonActionSheet
                isOpen={showActionSheet}
                onDidDismiss={() => setShowActionSheet(false)}
                buttons={[
                    {
                        text: 'Camara',
                        role: 'destructive',
                        icon: camera,
                        handler: () => {
                            takePicture()
                        }
                    }, {
                        text: 'Galeria de fotos',
                        icon: share,
                        handler: () => {
                            inputFile.current.open()
                        }
                    }
                ]}
            >
            </IonActionSheet>

            <IonToast
                isOpen={showIncomplet}
                onDidDismiss={() => setShowIncomplet(false)}
                message="Complete todos los campos obligatorios"
                position="top"
                duration={3000}
            />

            <IonAlert
                isOpen={showAlertError}
                onDidDismiss={() => setShowAlertError(false)}
                header={'No se puedo crear el producto'}
                message={alertMessage || 'Intente nuevamente mas tarde'}
                buttons={['Aceptar']}
            />

            <IonLoading
                isOpen={loading}
                backdropDismiss={false}
                keyboardClose={false}
                message={'Cargando...'}
                duration={5000}
            />

            <IonLoading
                isOpen={submitting}
                backdropDismiss={false}
                keyboardClose={false}
                message={progress || 'Espere por favor...'}
            />
        </App>
    )
}