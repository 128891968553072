import { client } from '../client'

import { PurchaseOrder } from 'common'

export const STATUS_PENDING = 'pending';
export const STATUS_CANCELED = 'canceled';
export const STATUS_PAID = 'paid';
export const STATUS_COMPLETED = 'completed';
export const STATUS_EXPIRED  = 'expired';
export const STATUS_RETURNED  = 'returned';
export const STATUS_MANUAL  = 'manual';

export const list = async ({ page = 1, query, status = [
    STATUS_PAID,
    STATUS_COMPLETED,
    STATUS_MANUAL
 ] }) => {
    const response = await client.get(`/purchase-orders`, {
        params: {
            page,
            query,
            status: status.join(',')
        }
    });
    return response.data
}

export const detail = async (id) => {
    const response = await client.get(`/purchase-orders/${id}`);
    return response.data.data
}

export const complete = async (id) => {
    const response = await client.post(`/purchase-orders/${id}/complete`);
    return response.data.data
}

export const cancel = async (id) => {
    const response = await client.post(`/purchase-orders/${id}/cancel`);
    return response.data.data
}

export const estimate = async (data) => {
    const response = await client.post(`/purchase-orders/estimate`, data);
    return response.data.data
}

export const create = async (data) => {
    const response = await client.post(`/purchase-orders`, data);
    return response.data.data
}

export const detailByUUID = async (uuid) => {
    const response = await client.get(`/purchase-orders/${uuid}`);
    return response.data.data
}

export const review = async (uuid, data) => {
    const response = await client.post(`/purchase-orders/${uuid}/review`, data);
    return response.data.data
}