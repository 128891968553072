import { client } from '../client'

export const list = async () => {
    const response = await client.get(`/payment-orders`);
    return response.data.data
}

export const detail = async (id) => {
    const response = await client.get(`/payment-orders/${id}`);
    return response.data.data
}

export const detailByUUID = async (uuid) => {
    const response = await client.get(`/payment-orders/${uuid}`);
    return response.data.data
}