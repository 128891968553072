import React from 'react';

export const ListTitle = ({ children, count, title, className }) => {

    title = title || children

    if (Array.isArray(title))
        title = title.join(' ')

    return (
        <div className={`App-Articulos-List-Title ${className} wow fadeInUp`} data-wow-duration="0.6s" data-wow-delay="0.5s">
            <h2 className="Title FontTitle1">{title}</h2>
            { count && <span className="Cantidad BgVioleta BorderAll">{count}</span>}
        </div>
    )
}
