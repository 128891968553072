
import React from 'react';

import config from 'config'

export const CarouselImage = ({ size = 'cover', path }) => {

    let url = `${config.url}/images/${size}/${path}`
    let href = `${config.url}/storage/${path}`

    if (path && path.includes("http"))
        url = href = path;


    return (
        <div className="Articulo-Slider-Slide">
            <a href={href} data-fancybox="gallery">
                <img className="Img ImgCaroucel img-fluid" src={url} alt="Title" />
            </a>
        </div>
    )
}
