import { client, clientWithCredentials } from './../client'

export const PROVIDER_FACEBOOK = 'facebook'
export const PROVIDER_GOOGLE = 'google'

export const login = async (credential) => {
    let response = await clientWithCredentials.post(`login`, credential)
    return response.data.data
}

export const refreshToken = async (refreshToken) => {
    let response = await clientWithCredentials.post(`refresh/token`, { refresh_token: refreshToken })
    return response.data.data
}


export const socialLogin = async (provider, accessToken) => {
    let response = await clientWithCredentials.post(`login/${provider}`, { access_token: accessToken })
    return response.data.data
}

export const logout = async () => {
    return await client.post(`/logout`)
}

export const revoke = async () => {
    return await client.post(`/revoke`)
}

export const forgotPassword = async (email) => {
    let response = await client.post(`/password/email`, { email })
    return (response.status === 200 /* Send */)
}

export const reset = async ({token, email, password, password_confirmation}) => {
    const response = await client.post(`/password/reset`, {
        token,
        email,
        password,
        password_confirmation,
    });
    
    return (response.status === 200 /* Send */)
}
