import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from "react-router-dom"
import {
    IonLoading,
    IonAlert
} from '@ionic/react'

import { PurchaseOrderService } from 'services'

import { App, Content, Button, Input, Spinner } from 'components'

import imgGood from 'assets/img/review/good.jpeg'
import imgBad from 'assets/img/review/bad.jpeg'
import imgRegular from 'assets/img/review/regular.jpeg'

export const Review = () => {

    const history = useHistory()
    const params = useParams()

    const [loading, setLoading] = useState(true)
    const [showAlertError, setShowAlertError] = useState(false)
    const [alertMessage, setAlertMessage] = useState()
    const [submitting, setSubmitting] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)

    const [purchaseOrder, setPurchaseOrder] = useState()
    const [valid, setValid] = useState(false)
    const [star, setStar] = useState()

    const load = async () => {
        setLoading(true)
        try {
            let purchaseOrder = await PurchaseOrderService.detailByUUID(params.uuid)
            setPurchaseOrder(purchaseOrder)
            setStar(purchaseOrder.review.star)
        } catch (error) {
            console.error(error)

            //history.replace('/404')

        }
        setLoading(false)
    }

    useEffect(() => {
        load()
    }, [])

    const changeStar = (event, star) => {
        event.preventDefault()

        if (purchaseOrder?.review)
            return

        setStar(star)
    }

    useEffect(() => {
        setValid(
            star > 0
        )
    }, [star])

    const send = async () => {
        setSubmitting(true)
        try {
            await PurchaseOrderService.review(params.uuid, {
                star,
                review: ''
            })
            setShowSuccess(true)
        } catch (error) {
            console.error(error)
            setAlertMessage(true)
        }
        setSubmitting(false)
    }

    const product = purchaseOrder?.products[0]?.product

    return (
        <App title="Califica la compra" titlebar>
            <Content>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <article className="App-DatosPago wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.5s">
                            <div className="App-Articulo-Detalle  px-0">
                                <div className="App-Brands mb-2">
                                    <img className="ImgLogo" src="assets/img/storeados-isologo-rose.png" alt="Storeados" />
                                </div>
                                {
                                    product && <>
                                        <div className="App-Articulo-Detalle-Header">
                                            <div className="BoxTitle">
                                                <h2 className="Title FontTitle1">{product?.name || 'Detalle del artículo'}</h2>
                                                <p className="Categorias"><span className="Categoria">{product.category?.category?.name}</span><span className="Categoria">{product.category?.name}</span></p>
                                            </div>
                                        </div>
                                    </>
                                }
                                {
                                    purchaseOrder &&
                                    <div className="my-5 text-center text-dark">
                                        <a href="#" className={`d-inline-block text-${star == 1 ? 'primary' : 'dark'}`} onClick={event => changeStar(event, 1)}>
                                            <img src={imgBad} alt="" />
                                            <br />Malo
                                            </a>
                                        <a href="#" className={`mx-5 d-inline-block text-${star == 2 ? 'primary' : 'dark'}`} onClick={event => changeStar(event, 2)}>
                                            <img src={imgRegular} alt="" />
                                            <br />Regular
                                            </a>
                                        <a href="#" className={`d-inline-block text-${star == 3 ? 'primary' : 'dark'}`} onClick={event => changeStar(event, 3)}>
                                            <img src={imgGood} alt="" />
                                            <br />Bueno
                                            </a>
                                    </div>
                                }
                                {
                                    purchaseOrder && (!purchaseOrder?.review
                                        ? <Button
                                            color="primary"
                                            onClick={() => send()}
                                            disabled={!valid || submitting}>
                                            Enviar
                                            </Button>
                                        : <Button
                                            disabled>
                                            Ya califico la compra
                                        </Button>
                                    )
                                }

                            </div>
                        </article>
                    </div>
                </div>
            </Content>

            <IonAlert
                isOpen={showSuccess}
                onDidDismiss={() => window.location.replace(product.url)}
                header={'Gracias'}
                message={'Su calificacion fue enviada'}
                buttons={['Aceptar']}
            />

            <IonAlert
                isOpen={showAlertError}
                onDidDismiss={() => setShowAlertError(false)}
                header={'No se puedo realizar la compra'}
                message={alertMessage || 'Intente nuevamente mas tarde'}
                buttons={['Aceptar']}
            />

            <IonLoading
                isOpen={loading}
                backdropDismiss={false}
                keyboardClose={false}
                message={'Espere por favor...'}
            />
        </App >
    )
}