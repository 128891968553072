import React, { useState, useEffect } from 'react'
import { useHistory, Link } from "react-router-dom"

import {
    isPlatform,
    IonContent,
    IonLoading,
    IonText,
    IonPage,
    IonTitle,
    IonToolbar,
    IonInput,
    IonItem,
    IonButton,
    IonItemDivider,
    IonAlert,
    IonIcon
} from '@ionic/react'

import { GooglePlus } from '@ionic-native/google-plus'

import { logoGoogle, logoFacebook, closeCircleOutline } from 'ionicons/icons';

import GoogleLogin from 'react-google-login';

import config from 'config'

import { useContext, useWOW } from 'hooks'

import { Button, App, Content, Input } from 'components'

import { UserService, FacebookService } from 'services'

export const Login = () => {

    // useWOW()

    const history = useHistory()
    const { auth } = useContext()

    const [submitting, setSubmitting] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [errors, setErrors] = useState({})

    const [showAlertError, setShowAlertError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('Intentelo nuevamente mas tarde');
    
    const [valid, setValid] = useState(false)

    const login = async () => {
        setSubmitting(true)
        try {
            await auth.login({ email, password })
            loginSuccess()
        } catch (error) {
            if (error?.response?.data?.errors) {

                setErrors(error?.response?.data?.errors)
            } else {
                setShowAlertError(true)
            }
            // const errors = error.response?.data?.errors;
            // if (errors?.invalid_grant)
            //     setErrorMessage('Verifique los datos de acceso y vuelva a interntarlo')
            loginFailed(error)
        }
    }

    const responseLoginWithGoogle = async (response) => {
        try {
            await auth.loginWithGoogle(response.accessToken)
            loginSuccess()
        } catch (error) {
            setErrorMessage('Intentelo nuevamente mas tarde')
            loginFailed(error)
        }
    }

    const loginWithGoogle = async (event) => {
        event.preventDefault()
        setSubmitting(true)
        try {
            let response = await GooglePlus.login({
                'webClientId': config.google_client_id,
                'offline': true
            })

            await auth.loginWithGoogle(response.accessToken)
            loginSuccess()
        } catch (error) {
            setErrorMessage('Intentelo nuevamente mas tarde')
            loginFailed(error)
        }
    }

    const loginWithFacebook = async (event) => {
        event.preventDefault()
        setSubmitting(true)
        try {
            let response = await FacebookService.login(['public_profile', 'email'])
            await auth.loginWithFacebook(response.authResponse.accessToken)
            loginSuccess()
        } catch (error) {
            setErrorMessage('Intentelo nuevamente mas tarde')
            loginFailed(error)
        }
    }

    const loginSuccess = async () => {

        let user = await UserService.user()
        if (user.profile_completed === true) {
            history.replace('/')
        } else {
            history.replace('/profile', { force: true })
        }
    }

    const loginFailed = (error) => {
        console.log("Login Error", error)
        setShowAlertError(true)
        setSubmitting(false)
    }

    useEffect(() => {
        setValid( 
            email.trim() !== '' &&
            password.trim() !== ''
        )
    }, [email, password])

    return (
        <App title="Login">
            <Content>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <article className="App-Login">
                            <div className="LoginBox-Img py-3">
                                <div className="BoxImg wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.5s">
                                    <img className="Img img-fluid" src="assets/img/storeados-logo-rose.png" alt="STOREADOS - Ahora vender en internet es fácil, rápido y seguro" />
                                </div>
                            </div>
                            <div className="LoginBox-Formulario">
                                <div className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="1s">
                                    <h1 className="Title">Ahora vender en internet es fácil, rápido y seguro</h1>
                                    <form className="FormularioLogin FormularioBase">
                                        <Input type="email"
                                            placeholder="Correo electrónico"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                            disabled={submitting}
                                            errors={errors['email']}
                                        />
                                        <Input type="password"
                                            placeholder="Contraseña"
                                            value={password}
                                            onChange={e => setPassword(e.target.value)}
                                            errors={errors['password']}
                                            disabled={submitting}
                                        />
                                        <div className="form-group">
                                            <div className="BoxLoginRedes">
                                                <p className="BoxLoginRedes-Text">O inicia sesión con:</p>
                                                <div className="BoxLoginRedes-Links">
                                                    <a className="BoxLoginRedes-Link LinkFacebook BorderAll" href="#" onClick={loginWithFacebook} disabled={submitting}><i className="fa fa-facebook"></i></a>
                                                    {
                                                        isPlatform('hybrid')
                                                            ? (
                                                                <a className="BoxLoginRedes-Link LinkGoogle BorderAll" href="#" onClick={loginWithGoogle} disabled={submitting}>
                                                                    <i className="fa fa-google"></i>
                                                                </a>
                                                            ) : (
                                                                <GoogleLogin
                                                                    clientId={config.google_client_id}
                                                                    onRequest={() => setSubmitting(true)}
                                                                    render={props => (
                                                                        <a className="BoxLoginRedes-Link LinkGoogle BorderAll" href="#"
                                                                            onClick={(event) => { event.preventDefault(); props.onClick() }}
                                                                            disabled={submitting}>
                                                                            <i className="fa fa-google"></i>
                                                                        </a>
                                                                    )}
                                                                    buttonText="Google"
                                                                    onSuccess={responseLoginWithGoogle}
                                                                    onFailure={(error) => loginFailed(error)}
                                                                    cookiePolicy={'single_host_origin'}
                                                                />
                                                            )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="1.5s">
                                            <div className="form-group">
                                                <Button onClick={() => login()} color="primary" disabled={!valid || submitting}>Ingresar</Button>
                                            </div>
                                        </div>
                                        <Link className="Link forgot-password" to="/forgot/password" disabled={submitting}>¿Olvide mi contraseña?</Link>
                                    </form>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </Content>

            <footer className="App-Footer wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="1.5s">
                <div className="container">
                    <div className="row">
                        <div className="col12 col-sm-12 col-md-12 col-lg-12">
                            <div className="BoxRegistrate">
                                <p className="Text">¿No tienes cuenta?</p>
                                <Link className="Link" to="/register" disabled={submitting}>Regístrate.</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            <IonAlert
                isOpen={showAlertError}
                onDidDismiss={() => setShowAlertError(false)}
                header={'No se puedo iniciar sesion'}
                message={errorMessage}
                buttons={['Aceptar']}
            />

            <IonLoading
                isOpen={submitting}
                backdropDismiss={false}
                keyboardClose={false}
                message={'Espere por favor...'}
            />
        </App>
    )
}

