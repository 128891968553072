import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from "react-router-dom"




import { App, Content, Button, Input, Spinner } from 'components'


export const Completed = () => {

    const history = useHistory()
    const params = useParams()

    return (
        <App title="Pago Realizado" titlebar backButton={false}>
            <Content margin>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <article className="App-DatosPago wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.5s">
                            <div className="App-Articulo-Detalle  px-0">
                                <div className="App-Brands mb-2">
                                    <img className="ImgLogo" src="assets/img/storeados-isologo-rose.png" alt="Storeados" />

                                </div>
                                <div className="my-5 text-center">
                                    <p><strong>Pago Realizado</strong></p>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </Content>
        </App>
    )
}

