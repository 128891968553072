import { client } from '../client'

export const DEFAULT_COUNTRY = {
    id: 1,
    code2: 'PY',
    code3: 'PYG',
    name: 'Paraguay',
}

export const list = async () => {
    const response = await client.get(`/countries`);
    return response.data.data
}

export const detail = async (id) => {
    const response = await client.get(`/countries/${id}`);
    return response.data.data
}

export const states = async (id) => {
    const response = await client.get(`/countries/${id}/states`);
    return response.data.data
}