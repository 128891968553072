import React, { useState, useEffect, useRef } from 'react'

import scriptLoader from 'react-async-script-loader'

import { Spinner } from 'components'

import config from 'config';

export const PaymentBancard = scriptLoader(
    `/assets/vendor/bancard/bancard-checkout-2.1.0${config.bancard_sanbox ? '-sandbox' : ''}.js`
)(({ hash, isScriptLoaded, isScriptLoadSucceed }) => {

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (!hash || !isScriptLoadSucceed) return;

        window.Bancard.Checkout.createForm('iframe-container', hash, {
            styles: {
                //'input-background-color': '#efefef',
                //'input-text-color': '#999999',
                //'input-border-color': '#efefef',
                //'input-placeholder-color': '#000000',
                'button-background-color': '#ff3388',
                'button-text-color': '#FFFFFF',
                'button-border-color': '#ff3388',
                //'form-background-color': '#999999',
                //'form-border-color': '#DDDDDD',
                'header-background-color': '#FFFFFF',
                //'header-text-color': '#333333',
                //'hr-border-color': '#B22222'
            }
        })
            
        setLoading(false)
    }, [isScriptLoaded])

    return (
        <>
        {loading && <Spinner className="mt-5"/>}
        <div style={{ width: '100%', marginTop: '5px' }} id="iframe-container"></div>
        </>
    )
})