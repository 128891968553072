import React, { useState, useEffect, Children } from 'react'
import { useHistory } from "react-router-dom"

import { 
    IonContent,
    IonLoading,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonItemDivider 
} from '@ionic/react'

import { UserService } from 'services'

import { useContext } from 'hooks'

import { Title } from 'components'

import {
    TYPE_PRODUCT_STOCK_SOLDOUT,
    TYPE_PRODUCT_PUBLISHED,
    TYPE_PURCHASE_ORDER_COMPLETED,
    TYPE_PRUCHASE_ORDER_REVIEW_CREATED
} from 'common/PushNotification'


import {
    Plugins,
    PushNotification,
    PushNotificationToken,
    PushNotificationActionPerformed
} from '@capacitor/core'

const { PushNotifications, LocalNotifications } = Plugins

export const Notifications = ({children}) => {

    const history = useHistory()

    const registerPushNotification = async () => {

        PushNotifications.addListener('registration', async (token) => {
            await UserService.subscriber('fcm', token.value)
        })

        PushNotifications.addListener('registrationError', (error) => {
            console.log('Error on registration: ' + JSON.stringify(error))
        })

        PushNotifications.addListener('pushNotificationReceived', async (notification) => {
            await LocalNotifications.schedule({
                notifications: [
                    {
                        ...notification,
                        id: notification.id.match(/:([0-9]*)%/)[1]
                    }
                ]
            });
        })

        LocalNotifications.addListener('localNotificationReceived', (notification) => {
            console.log('Local Push areceived: ' + JSON.stringify(notification))
        })
        
        LocalNotifications.addListener('localNotificationActionPerformed', (notification) => {
            console.log('Local Push action performed: ' + JSON.stringify(notification))
            const payload = notification.notification.data;
            actionPushNotification(payload)
        })

        PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
            console.log('Push action performed: ' + JSON.stringify(notification))
            const payload = notification.notification.data;
            actionPushNotification(payload)
        })

        await PushNotifications.register()
    }

    const actionPushNotification = (payload) => {
        switch(payload.type) {
            case TYPE_PRODUCT_STOCK_SOLDOUT:
                history.push(`/products/${payload.product_id}`);
            break;
            case TYPE_PRODUCT_PUBLISHED:
                history.push(`/products/${payload.product_id}`);
            break;
            case TYPE_PURCHASE_ORDER_COMPLETED:
            case TYPE_PRUCHASE_ORDER_REVIEW_CREATED:
                history.push(`/sales/${payload.purchase_order_id}`);
            break;
        }
    }

    useEffect(() => {
        //currentUser()
        registerPushNotification()
    }, [])

    return (
        <>
            {children}
        </>
    )
}

