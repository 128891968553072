import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from "react-router-dom"
import {
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonRefresher,
    IonRefresherContent
} from '@ionic/react'

import { parseJSON, format } from 'date-fns'

import { PurchaseOrderService } from 'services'

import { App, Content, ListTitle, InputSearch, Image, Spinner } from 'components'

import { PurchaseOrder, currencyFormat } from 'common'

import { useWOW } from 'hooks'

import config from 'config'

import productoSinImagen from 'assets/img/producto-sin-imagen.png'

export const ListSales = () => {

    const history = useHistory()

    const refresh = useRef()
    const infiniteScroll = useRef()
    const timer = useRef()

    const [sales, setSales] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const [search, setSearch] = useState()
    const [submitting, setSubmitting] = useState(false)
    const [more, setMore] = useState(false)
    const [meta, setMeta] = useState({})

    const loadSales = async (page, isRefresh) => {
        try {
            let { data, meta } = await PurchaseOrderService.list({
                page,
                query: search
            })

            setCurrentPage(page)
            setMeta(meta)
            setMore(meta.to < meta.total)
            setSales(
                page == 1 ? data : [...sales, ...data]
            )
        } catch (error) {
            console.error(error)
        }

        infiniteScroll.current && infiniteScroll.current.complete()

        isRefresh && refresh.current && refresh.current.complete()

        setSubmitting(false)
    }

    const moreSales = async () => {
        let nextPage = currentPage + 1;
        loadSales(nextPage);
    }

    useEffect(() => {
        setSubmitting(true)
        loadSales(1);
    }, [])

    useEffect(() => {
        timer.current && clearTimeout(timer.current)
        timer.current = setTimeout(() => {
            setSubmitting(true)
            loadSales(1);
        }, 500)

        return () => {
            timer.current && clearTimeout(timer.current)
        }
    }, [search])

    const renderSales = (sale) => {
        const buyer = sale.buyer;
        const order_product = sale.products[0];
        const product = order_product.product;
        const delivery_method = sale.delivery_method;
        const payment_method = sale.payment_method;

        const date = format(parseJSON(sale.created_at), 'MM/dd/yyyy H:mm:ss');

        let status = 'Pendiente';
        switch (sale.status) {
            case PurchaseOrder.STATUS_CANCELED:
                status = 'Cancelado';
                break;
            case PurchaseOrder.STATUS_PAID:
                status = 'Pagado (Pendiente de envio)';
                break;
            case PurchaseOrder.STATUS_COMPLETED:
                status = 'Completo';
                break;
            case PurchaseOrder.STATUS_EXPIRED:
                status = 'Expirado';
                break;
            case PurchaseOrder.STATUS_RETURNED:
                status = 'Devuelto';
                break;
        }

        let icon = ''
        switch (sale.review?.star) {
            case 1:
                icon = 'frown'
                break
            case 2:
                icon = 'meh'
                break
            case 3:
                icon = 'smile'
                break
        }
        return (
            <div
                href="#"
                key={sale.id}
                onClick={e => { e.preventDefault(); history.push(`sales/${sale.id}`, { sale }) }}
                className="BoxArtVendido wow fadeInUp"
                data-wow-duration="0.6s"
                data-wow-delay="0.5s"
            >
                <Image path={product.photo} invalid={productoSinImagen} />
                <div className="BoxDetalle">
                    <h3 className="BoxDetalle-Title FontTitle2">{product.name} / {status}</h3>
                    <p className="BoxDetalle-Text">
                        <strong>Comprador:</strong> {buyer.first_name} {buyer.lastname}
                        {payment_method && <><br /><strong>Pago:</strong> {payment_method.name}</>}
                        {delivery_method && <><br /><strong>Entrega:</strong> {delivery_method.name}</>}
                    </p>
                    <span className="Cantidad BgVioleta BorderAll">{order_product.quantity}</span>
                </div>
                <div className="BoxDatos">
                    {icon && <span className="Visualizacion"><i className={`fa fa-${icon}-o`}></i></span>}
                </div>
            </div>
        )
    }

    return (
        <App title="Mis Ventas" titlebar backButton={false}>
            <Content margin className="App-ArtVendidos-List-Artitulos">
                <IonRefresher slot="fixed" onIonRefresh={() => loadSales(1, true)} ref={refresh}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                <InputSearch placeholder="Buscar en mis ventas"
                    defaultValue={search}
                    onChange={(event) => setSearch(event.target.value)} />
                <ListTitle className="App-ArtVendidos-List-Title" count={meta.total  ?? ''}>
                    Artículos vendidos
                </ListTitle>
                <div className="App-ArtVendidos-List">
                    {submitting ? <Spinner /> : sales.map(sale => renderSales(sale))}
                </div>
                <IonInfiniteScroll
                    threshold="100px"
                    onIonInfinite={() => moreSales()}
                    disabled={!more}
                    ref={infiniteScroll}
                >
                    <IonInfiniteScrollContent
                        loadingSpinner="crescent" />
                </IonInfiniteScroll>
            </Content>
        </App>

    )
}