import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom"

import {
    IonAlert
} from '@ionic/react'

import { AuthService } from 'services'

import { App, Content, Input, Button } from 'components'

export const ForgotPassword = () => {

    const history = useHistory()

    const [email, setEmail] = useState('')
    const [errors, setErrors] = useState({})

    const [success, setSuccess] = useState(false);
    const [showAlertError, setShowAlertError] = useState(false);
    const [submitting, setSubmitting] = useState(false)

    const forgotPassword = async (email) => {
        setErrors([])
        setSubmitting(true)
        try {
            await AuthService.forgotPassword(email)
            setSuccess(true)
        } catch (error) {
            if (error?.response?.data?.errors) {
                setErrors(error?.response?.data?.errors)
            } else {
                setShowAlertError(true)
            }
        }
        setSubmitting(false)
    }

    return (
        <App title="Restablecer contraseña" titlebar >
            <Content margin>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <article className="wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.5s">
                            <h3>¿Tienes problemas para iniciar sesión?</h3>
                            Ingresa tu correo electrónico y te enviaremos un enlace para recuperar el acceso a tu cuenta.
                        </article>
                        <form className="FormularioLogin FormularioBase mt-2" onSubmit={(e) => e.preventDefault()}>
                            <Input
                                type="email"
                                value={email}
                                placeholder="Correo electrónico"
                                onChange={e => setEmail(e.target.value)}
                                disabled={submitting}
                                errors={errors['email']}
                            />

                            <div className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="1.5s">
                                <div className="form-group">
                                    <Button type="button" color="primary" onClick={() => forgotPassword(email)} >Restablecer contraseña</Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Content>
            <IonAlert
                isOpen={success}
                header={'¡Recordatorio de contraseña enviado!'}
                message={`Se envió un correo electrónico a ${email} con los paso a seguir para restablecer la contraseña`}
                buttons={['Aceptar']}
                onDidDismiss={() =>  history.goBack()}
            />
            
            <IonAlert
                isOpen={showAlertError}
                onDidDismiss={() => setShowAlertError(false)}
                header={'No se puedo enviar el correo electrónico'}
                message={'Intente nuevamente mas tarde'}
                buttons={['Aceptar']}
            />
        </App>

    )
}