import React from 'react';

export const ButtonBox = ({ children, mt = 0, color = '', active = true,  icon = '', ...rest }) => {
    return (
        <div className="App-DatosPago-Forma">
            <div className={`BoxTarjeta App-ButtonBox BorderAll BgAzul  ${ active ? 'Active' : ''} mt-${mt}`} {...rest}>
                {icon && <i className={`Icon IconTarjeta fa fa-${icon}`}></i>}
                <p className="Title"> {children}</p>
            </div>
        </div>
    )
}
