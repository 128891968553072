import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom"
import {
    IonLoading,
    IonAlert
} from '@ionic/react'

import { closeCircleOutline } from 'ionicons/icons';

import { RegisterService } from 'services'

import { App, Content, Input, ListTitle, Button } from 'components'

export const Register = () => {

    const history = useHistory()

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('')
    const [errors, setErrors] = useState({})
    const [valid, setValid] = useState()

    const [showAlertError, setShowAlertError] = useState(false);
    const [submitting, setSubmitting] = useState(false)

    const register = async (event) => {
        event.preventDefault()

        setSubmitting(true)
        try {
            await RegisterService.register({
                first_name: firstName,
                last_name: lastName,
                phone,
                email,
                password,
                password_confirmation: passwordConfirmation,
            })

            history.replace('/login')
        } catch (error) {
            if (error?.response?.data?.errors) {
                setErrors(error?.response?.data?.errors)
            } else {
                setShowAlertError(true)
            }
        }

        setSubmitting(false)
    }

    useEffect(() => {
        setValid( 
            firstName.trim() !== '' &&
            lastName.trim() !== '' &&
            phone.trim() !== '' &&
            email.trim() !== '' &&
            password.trim() !== '' &&
            passwordConfirmation.trim() !== ''
        )
    }, [firstName, lastName, phone, email, password, passwordConfirmation])

    return (
        <App title="Registrarme" titlebar onBack={() => history.replace('/login')}>
            <Content margin>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <article className="App-DatosPago wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.5s">
                            <div className="App-MiCuenta-DatosFormularios mt-4">
                                <div className="App-MiCuenta-DatosFormularios-Box mb-4 wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.5s">
                                    <ListTitle>Datos personales</ListTitle>
                                    <form className="FormularioLogin FormularioBase" action="#">
                                        <Input type="text"
                                            placeholder="Nombre"
                                            value={firstName}
                                            onChange={e => setFirstName(e.target.value)}
                                            errors={errors['first_name']}
                                            disabled={submitting}
                                        />
                                        <Input type="text"
                                            placeholder="Apellido"
                                            value={lastName}
                                            onChange={e => setLastName(e.target.value)}
                                            errors={errors['last_name']}
                                            disabled={submitting}
                                        />
                                        <Input type="tel"
                                            placeholder="Teléfono"
                                            value={phone}
                                            onChange={e => setPhone(e.target.value)}
                                            errors={errors['phone']}
                                            disabled={submitting}
                                        />
                                        <ListTitle>Datos de acceso</ListTitle>
                                        <Input type="text"
                                            placeholder="Correo electrónico"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                            errors={errors['email']}
                                            disabled={submitting}
                                        />
                                        <Input type="password"
                                            placeholder="Contraseña"
                                            value={password}
                                            onChange={e => setPassword(e.target.value)}
                                            errors={errors['password']}
                                            disabled={submitting}
                                        />
                                        <Input type="password"
                                            placeholder="Confirme la Contraseña"
                                            value={passwordConfirmation}
                                            onChange={e => setPasswordConfirmation(e.target.value)}
                                            errors={errors['password_confirmation']}
                                            disabled={submitting}
                                        />
                                        <Button onClick={register} color="primary" disabled={!valid || submitting}>Registrarme</Button>
                                    </form>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </Content>

            <IonAlert
                isOpen={showAlertError}
                onDidDismiss={() => setShowAlertError(false)}
                header={'No se puedo crear la cuenta'}
                message={'Intente nuevamente mas tarde'}
                buttons={['Aceptar']}
            />

            <IonLoading
                isOpen={submitting}
                backdropDismiss={false}
                keyboardClose={false}
                message={'Espere por favor...'}
                duration={5000}
            />
        </App>
    )
}