import React from 'react';

import { IonImg } from '@ionic/react';
import { Button } from 'components';

import config from 'config';

export const ImageAndDelete = ({ 
    type = 'medium', 
    path, src, 
    size = '200', 
    onError, 
    onDidLoad, 
    onDelete, 
    onClick, 
    textDelete = 'Eliminar', 
    check
}) => {   
    let url

    if (!src) {
        url = `${config.url}/images/${type}/${path}`

        if (path && path.includes("http"))
            url = path;
    }

    return (
        <>
            <IonImg src={url || src}
                style={{
                    background: '#f1f0f0',
                    height: size + 'px',
                    objectFit: 'contain',
                    borderTopRightRadius: '7px',
                    borderTopLeftRadius: '7px',
                    overflow: 'hidden'
                }}
                onClick={(event) => onClick && onClick(event) }
                onIonError={(event) => onError && onError(event)}
                onIonImgDidLoad={(event) => onDidLoad && onDidLoad(event)}
            />
            { check && <i className="IconChek IconChek--foto fa fa-check-circle-o"></i> }
            <Button onClick={(event) => onDelete && onDelete(event)} className="BorderBottom">{textDelete}</Button>
        </>
    )
}
