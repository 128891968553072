import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react'

import { Button } from 'components'

import { Plugins } from '@capacitor/core'

import './index.css'

const { Geolocation } = Plugins

export const Maps = forwardRef(({
    onClick,
    onContinue,
    marker,
    autolocation = true,
    location
}, ref) => {

    const map = useRef()
    const googleMap = useRef()
    const googleMarker = useRef()

    const [position, setPosition] = useState(false)

    const centerGPSLocation = async () => {
        try {
            const position = await Geolocation.getCurrentPosition()
            var pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            };
            googleMap.current.setCenter(pos);

           /* if (googleMarker.current) {
                googleMarker.current.setMap(null)
            }

            googleMarker.current = new window.google.maps.Marker({
                position: pos,
                map: googleMap.current
            })*/

            setPosition(pos)
        } catch (error) {
            console.log(error)
        }
    }

    useImperativeHandle(ref, () => ({
        centerGPSLocation: centerGPSLocation
    }));

    useEffect(() => {
        try {
            if(!window.google )
                return
            
            let center = {                
                lat: location?.lat || -25.2637535,
                lng: location?.lng || -57.5759125
            }

            googleMap.current = new window.google.maps.Map(map.current, {
                center: new window.google.maps.LatLng(center.lat, center.lng),
                zoomControl: true,
                scaleControl: false,
                streetViewControl: false,
                fullscreenControl: false,
                zoomControlOptions: {
                    position: window.google.maps.ControlPosition.TOP_RIGHT
                },
                zoom: 18,
                disableDefaultUI: true,
                noClear: true
            })


            if (marker) {
            /*    if (googleMarker.current) {
                    googleMarker.current.setMap(null)
                }

                googleMarker.current = new window.google.maps.Marker({
                    position: location,
                    map: googleMap.current
                })
            */
                setPosition(location)
            }

            /*googleMap.current.addListener('click', function (mapsMouseEvent) {
                if (googleMarker.current) {
                    googleMarker.current.setMap(null)
                }
                googleMarker.current = new window.google.maps.Marker({
                    position: mapsMouseEvent.latLng,
                    map: googleMap.current
                });

                let pos = {
                    lat: mapsMouseEvent.latLng.lat(),
                    lng: mapsMouseEvent.latLng.lng()
                }

                setPosition(pos)
                onClick && onClick(pos)
            })*/

            googleMap.current.addListener( "dragend", function () {
                let center = googleMap.current.getCenter();
                let pos = {
                    lat: center.lat(),
                    lng: center.lng()
                }
                setPosition(pos)
                onClick && onClick(pos)
            });

            autolocation && centerGPSLocation()
        } catch (error) {
            console.error(error)
        }
    }, [])

    return (
        <div className="Maps row">
            <div ref={map} className="Maps__map">
                <div className="Maps__pin"></div>
            </div>
            <div className="container p-0 Maps__footer">
                <Button color="danger" mb={0} disabled={!position} onClick={() => { onContinue && onContinue(position) }}>Continuar</Button>
            </div>
        </div>
    )
})

