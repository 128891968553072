import { client } from '../client'

export const all = async () => {
    const response = await client.get(`/payment-methods`);
    return response.data.data
}

export const detail = async (id) => {
    const response = await client.get(`/payment-methods/${id}`);
    return response.data.data
}