import React from 'react';

import {
    Route as ReactRoute,
    Redirect
} from "react-router-dom";

import { useContext } from '../hooks'
import { SplashScreen } from './../pages/SplashScreen';

export const Route = ({ children, auth, guest, ...rest }) => {

    const context = useContext()

    if(!context.auth.isLoaded()) {
        return (
            <ReactRoute>
                <SplashScreen/>
            </ReactRoute>
        )
    }

    if (guest && context.auth.isAuthenticated()) {
        return <Redirect
            to={{
                pathname: "/"
            }}
        />
    }

    if (auth && !context.auth.isAuthenticated()) {
        return <Redirect
            to={{
                pathname: "/login"
            }}
        />
    }

    return <ReactRoute {...rest}>{children}</ReactRoute>
}


