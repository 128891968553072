import { useRef, useState } from 'react'
import { Plugins } from '@capacitor/core'

import jwt_decode from 'jwt-decode'

import * as authService from '../services/Auth'
import * as userService from '../services/User'
import { setClientAutorizationToken, setClientInterceptorResponse } from '../client'
import { useHistory } from 'react-router'

const { Storage } = Plugins

export const useAuth = () => {
    const KEY_TOKEN = 'token'

    const [loaded, setLoaded] = useState(false)

    const history = useHistory()
    const token = useRef(false)

    const load = async () => {
        if (!token.current) {
            let { value } = await Storage.get({ key: KEY_TOKEN })

            if (value === 'undefined')
                value = undefined

            if (value)
                value = JSON.parse(value)

            token.current = value

            setClientInterceptorResponse(apply, async () => {
                await clean()
                window.location.reload()
            })
            
            setClientAutorizationToken(token.current)

            setLoaded(true);
        }
    }

    const save = async () => {
        await Storage.set({ key: KEY_TOKEN, value: JSON.stringify(token.current) })
    }

    const clean = async () => {
        token.current = null
        setClientAutorizationToken(null)
        await Storage.remove({ key: KEY_TOKEN })
    }

    const getAuthToken = () => {
        return { ...token.current }
    }

    const getAccessToken = () => {
        return {
            token_type: token.current.token_type,
            access_token: token.current.access_token
        }
    }

    const getRefreshToken = () => {
        return token.current.refresh_token
    }

    const getAccessTokenWithPayload = () => {
        const token = getAccessToken()

        token.payload = null
        try {
            if (token.access_token)
                token.payload = jwt_decode(token.access_token)
        } catch (e) { }

        return token
    }

    const isAccessTokenExpired = () => {
        if (!token.current)
            return true

        try {
            const { payload } = getAccessTokenWithPayload()

            if (payload)
                return payload.exp <= Math.round(Date.now() / 1000)
        } catch (e) { }

        return true
    }

    const isAuthenticated = () => {
        if (token.current)
            return true

        return false
    }

    const apply = async (value) => {
        setClientAutorizationToken(value)
        token.current = value && { ...value }
        await save()
    }

    const login = async (credentials) => {
        const value = await authService.login(credentials)
        await apply(value)
    }

    const loginWithGoogle = async (accessToken) => {
        const value = await authService.socialLogin(authService.PROVIDER_GOOGLE, accessToken)
        await apply(value)
    }

    const loginWithFacebook = async (accessToken) => {
        const value = await authService.socialLogin(authService.PROVIDER_FACEBOOK, accessToken)
        await apply(value)
    }

    const refreshToken = async () => {
        try {
            const value = await authService.refreshToken(token.current.refresh_token)
            await apply(value)
            return true
        } catch (error) { }

        return false
    }

    const logout = async () => {
        try {
            await userService.unsubscriber()
        } catch (error) {}
        try {
            await authService.logout()
        } catch (error) {}

        await clean()
    }

    const revoke = async () => {
        try {
            await userService.unsubscriber()
            await authService.revoke()
        } catch (error) {
            console.error(error)
        }
        await clean()
    }

    const isLoaded = () => {
        return loaded;
    }

    return {
        load,
        isLoaded,
        login,
        loginWithGoogle,
        loginWithFacebook,
        refreshToken,
        logout,
        revoke,
        getAuthToken,
        getAccessToken,
        getRefreshToken,
        getAccessTokenWithPayload,
        isAuthenticated,
        isAccessTokenExpired
    }
}