import axios from 'axios'
import config from './config'
import createAuthRefreshInterceptor from 'axios-auth-refresh';

const { url, version, client_id, client_secret } = config

const baseURL = `${url}/api/v${version}/`

const headers = {
    'X-Requested-With': 'XMLHttpRequest',
    'Accept': 'application/json',
    'Accept-Type': 'application/json'
}

const timeout = 3000;

let refreshToken = null

export const client =
    axios.create({
        baseURL,
        headers,
       // timeout,
    })


export const clientWithCredentials =
    axios.create({
        baseURL,
        headers,
        //timeout,
        transformRequest: [
            (data, headers) => ({ client_id, client_secret, ...data }),
            ...axios.defaults.transformRequest
        ],
    })

export const setClientAutorizationToken = (token) => {
    if (!token) {
        delete client.defaults.headers['Authorization']
        refreshToken = null
    } else {
        client.defaults.headers['Authorization'] = `${token.token_type || 'Bearer'} ${token.access_token || ''}`
        refreshToken = token.refresh_token
    }
}

client.interceptors.request.use(request => {
    request.headers['Authorization'] = client.defaults.headers['Authorization'];
    return request;
});

export const setClientInterceptorResponse = (callback, callbackError) => {
    createAuthRefreshInterceptor(client, (error) => interceptorRefreshToken(error, callback, callbackError), { 
        skipWhileRefreshing: false, 
        retryInstance: client
    });
}

export const interceptorRefreshToken = (error, callback, callbackError) => {
    return clientWithCredentials.post(`refresh/token`, { refresh_token: refreshToken }).then(request => {
        const token = request.data.data
        setClientAutorizationToken(request.data.data)
        error.config.headers['Authorization'] = `${token.token_type || 'Bearer'} ${token.access_token || ''}`
        error.response.config.headers['Authorization'] = `${token.token_type || 'Bearer'} ${token.access_token || ''}`
        callback && callback(token).then( () => {
            return Promise.resolve();
        }) 
    }).catch( e => {    
        callbackError && callbackError().then( () => {
            return Promise.resolve();
        }) 
    });
}